import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Form, Input, message } from 'antd';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { loginSelector, errorSelector, profileSelector, interactionSelector } from '../../redux/selectors';
import { getLoginData, getProfileData, getProfileDataCompany, getResetPassword, getInteraction } from '../../redux/actions';
import { getLocalStorageItem, setLocalStorageItem, removeQueryParam, encodeString } from './../../utils/utilities';
import { CHARITY, COMPANY } from '../../constants';
import StyledText from '../../components/StyledText';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import CustomModal from '../../components/Modal';

const LoginPage = ({ className }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loginObj = useAppSelector(loginSelector);
    const errorText = useAppSelector(errorSelector)?.message;
    const { charity, company } = useAppSelector(profileSelector) || {};
    const interactions = useAppSelector(interactionSelector) || {};

    const [isAuthenticationError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const baseClassName = clsx('LoginDetailPage', className);
    const [messageApi, contextHolder] = message.useMessage();
    const actions = JSON.parse(getLocalStorageItem("actions"));

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Your password has been reset. Please log in',
            className: 'custom-class',
            style: { marginTop: '5vh' },
        });
    };

    const handleCharityLoginRoutes = () => dispatch(getProfileData());
    const handleCompanyLoginRoutes = () => dispatch(getProfileDataCompany());

    useEffect(() => {
        const isResetPassword = getLocalStorageItem('forgot_password');
        if (isResetPassword) {
            success();
            localStorage.removeItem('forgot_password');
        }

        if (loginObj) {
            setLoading(false);
            const decodedToken = jwtDecode(loginObj.IDToken);
            setLocalStorageItem('device_key', loginObj?.DeviceKey);
            setLocalStorageItem('userType', decodedToken?.['custom:entity']);
            setLocalStorageItem('id_Token', loginObj?.IDToken);
            setLocalStorageItem('user_details', decodedToken?.email);

            const userType = getLocalStorageItem('userType');
            if (userType === CHARITY) {
                handleCharityLoginRoutes();
            } else if (userType === COMPANY) {
                handleCompanyLoginRoutes();
            }
        }

        if (errorText) {
            setError(true);
            setLoading(false);
        }
    }, [loginObj, errorText]);

    useEffect(() => {
        const userType = getLocalStorageItem('userType');
        if (loginObj?.IDToken && actions?.companyName) {
            navigate(`/impact-maker/discover//${actions?.programId}`)
            return
        }
        if (loginObj?.IDToken && userType === CHARITY) {
            if (charity?.profilePercentage === "100") {
                dispatch(getInteraction({
                    sortBy: "programName",
                    asc: "true",
                    filterBy: {
                        status: [
                            "Pending",
                            "InEdit"
                        ]
                    }
                }))
            }
            else if ((Number.isInteger(charity?.profilePercentage) !== "100")) {
                navigate('/impact-maker/profile/create/start');
            }
        }
    }, [charity?.profilePercentage, loginObj?.IDToken]);

    useEffect(() => {
        if (Array.isArray(interactions) && charity?.profilePercentage === "100") {
            if (interactions?.length) {
                navigate('/impact-maker/applications/actions');
            } else {
                navigate('/impact-maker/discover');
            }
        }
    }, [interactions])

    useEffect(() => {
        const userType = getLocalStorageItem('userType');
        if (loginObj?.IDToken && userType === COMPANY) {
            if (company?.profilePercentage < 100 && Number.isInteger(company.profilePercentage)) {
                navigate('/company-profile-home');
            } else if (company?.profilePercentage === 100 && userType === COMPANY) {
                navigate('/company/submissions');
            }
        }
    }, [company?.profilePercentage, loginObj?.IDToken]);

    const onFinish = (values) => {
        const data = { email: values.email, password: values.password };
        setLoading(true);
        const storedData = { email: values.email, password: encodeString(values.password) }
        setLocalStorageItem('userDetails', JSON.stringify(storedData));
        dispatch(getLoginData(data));
    };

    const handleForgetPassword = () => {
        const data = { email: form.getFieldValue('email') };
        dispatch(getResetPassword(data));
        setIsModalOpen(true);
    };

    const handleClick = () => {
        navigate('/signup');
        removeQueryParam();
    };

    const isLoggedOut = window.location.search === '?loggedOut';

    return (
        <div className={baseClassName}>
            <div className="wrap">
                {contextHolder}
                <div className="leftContainer">
                    {actions?.companyName && <>
                        <img alt="companyLogo" className="cameraIcon" src={actions?.companyLogo} />
                        <StyledText as="p" variant="H1b" className="signupTitle">
                            {`${intl.formatMessage({ id: 'to_apply_to_the' })} ${actions?.companyName} ${actions?.programName}`}
                        </StyledText>
                        <div className='signUpDetailsContainer'>
                            <div className='textContainer'>
                                <StyledText as="p" variant="B3d" className="signupTitle">
                                    {intl.formatMessage({ id: 'step_1' })}:
                                </StyledText>
                                <StyledText as="p" variant="B3" className="signupTitle">
                                    {intl.formatMessage({ id: 'log_in_or_sign_up_to_create_your_free_bizGive_account' })}
                                </StyledText>
                            </div>
                            <div className='textContainer'>
                                <StyledText as="p" variant="B3d" className="signupTitle">
                                    {intl.formatMessage({ id: 'step_2' })}:
                                </StyledText>
                                <StyledText as="p" variant="B3" className="signupTitle">
                                    {intl.formatMessage({ id: 'if_youre_new_to_BizGive_create_an_organisation_profile' })}
                                </StyledText>
                            </div>
                            <div className='textContainer'>
                                <StyledText as="p" variant="B3d" className="signupTitle">
                                    {intl.formatMessage({ id: 'step_3' })}:
                                </StyledText>
                                <StyledText as="p" variant="B3" className="signupTitle">
                                    {intl.formatMessage({ id: 'complete_your_application_to_the_programme' })}
                                </StyledText>
                            </div>
                            <div className='textContainer'>
                                <StyledText as="p" variant="B3d" className="signupTitle">
                                    {intl.formatMessage({ id: 'step_4' })}:
                                </StyledText>
                                <StyledText as="p" variant="B3" className="signupTitle">
                                    {intl.formatMessage({ id: 'receive_alerts_when_action_is_required_or_a_funding_decision_is_made' })}
                                </StyledText>
                            </div>
                            <div className='textContainer'>
                                <StyledText as="p" variant="B3d" className="signupTitle">
                                    {intl.formatMessage({ id: 'step_5' })}:
                                </StyledText>
                                <StyledText as="p" variant="B3" className="signupTitle">
                                    {intl.formatMessage({ id: 'apply_to_other_programmes_from_the_discover_section' })}
                                </StyledText>
                            </div>
                        </div>
                    </>}
                    {isLoggedOut && (
                        <StyledText as="p" variant="H1" className="title">
                            {intl.formatMessage({ id: 'you_have_been_logged_out_due_to_inactivity' })}
                        </StyledText>
                    )}
                </div>
                <div className="fright">
                    <div className="loginContainer">
                        <StyledText as="p" variant="H2" className="title">
                            {intl.formatMessage({ id: 'login' })}
                        </StyledText>
                        <div className="signUpContainer">
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'not_registered_yet' })}
                            </StyledText>
                            <button className="hereButton" onClick={handleClick}>
                                <StyledText as="p" variant="B3" className="signUp">
                                    {intl.formatMessage({ id: 'sign_up' })}
                                </StyledText>
                            </button>
                        </div>
                        {isAuthenticationError && (
                            <Alert
                                type="error"
                                showIcon
                                showAfterTime
                                position="static"
                                message={intl.formatMessage({ id: 'please_enter_a_valid_password_using_the_guidance_below' })}
                                visible={isAuthenticationError}
                            />
                        )}
                        <Form form={form} name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: 'email', message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }) },
                                    { required: true, message: intl.formatMessage({ id: 'please_enter_email' }) },
                                ]}
                            >
                                <Input placeholder="E-mail" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: intl.formatMessage({ id: 'please_enter_password' }) }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <button type="button" className="forgotPassword" onClick={handleForgetPassword}>
                                {intl.formatMessage({ id: 'forgot_password' })}
                            </button>
                            <Form.Item>
                                <Button variant="primary" htmlType="submit" className="loginButton" loading={isLoading}>
                                    {intl.formatMessage({ id: 'log_in' })}
                                </Button>
                            </Form.Item>
                        </Form>
                        <CustomModal
                            title={intl.formatMessage({ id: 'forgot_password_header' })}
                            className="forgotPasswordModal"
                            open={isModalOpen}
                            onOk={() => setIsModalOpen(false)}
                            onCancel={() => setIsModalOpen(false)}
                            closable={false}
                            footer={null}
                        >
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'forgot_password_line1' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'forgot_password_line2' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'forgot_password_line3' })}
                            </StyledText>
                        </CustomModal>
                    </div>
                    <div className="userTermsContainer">
                        <StyledText as="p" variant="H2" className="title">
                            <a href="https://bizgiveworld.com/user-terms/" target="_blank" rel="noreferrer">
                                {intl.formatMessage({ id: 'user_terms' })}
                            </a>
                        </StyledText>
                        <StyledText as="p" variant="H2" className="signUp">
                            <a href="https://bizgiveworld.com/privacy-policy/" target="_blank" rel="noreferrer">
                                {intl.formatMessage({ id: 'privacy_policy' })}
                            </a>
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StyledLoginPage = styled(LoginPage)`
  &.LoginDetailPage {
    .wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media only screen and (max-width: ${(props) => props.theme.breakpoints?.medium}) {
        flex-direction: column;
      }
    }
    .leftContainer {
      float: left;
      width: 56%;
      height: 400px;
      padding-block: 35px;
      margin-top: 5%;
      @media only screen and (max-width: ${(props) => props.theme.breakpoints?.medium}) {
        height: fit-content;
      }
      .signupTitle{
                padding-bottom: 15px;
            }
            .signUpDetailsContainer{
                display: flex;
                flex-direction: column;
                gap: 15px;
                .textContainer{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }
            }
      .cameraIcon{
                margin-bottom: 20px;
                height: 100px;
                width: 100px;
            }
    }
    .fright {
      float: right;
      height: 95vh;
      width: 40%;
      background: #00aa9d;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media only screen and (max-width: ${(props) => props.theme.breakpoints?.medium}) {
        height: 87vh;
        width: 100%;
        right: 18px;
        position: relative;
      }
    }
    .loginContainer {
      background: white;
      padding: 20px;
      border-radius: 10px;
      gap: 10px;
      display: flex;
      flex-direction: column;
      margin-top: 20%;
      @media only screen and (max-width: ${(props) => props.theme.breakpoints?.medium}) {
        margin-top: 5%;
      }
      .signUpContainer {
        display: flex;
        .signUp {
          padding-left: 4px;
        }
      }
      .hereButton {
        border: 0;
        background: transparent;
        padding-inline: 0;
        text-decoration: underline;
        color: #04ac9c;
        cursor: pointer;
        margin-top: -1px;
        p {
          color: #04ac9c;
        }
      }
      .forgotPassword {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        padding-inline: 0;
      }
      .loginButton {
        margin-top: 20px;
      }
    }
    .userTermsContainer {
      display: flex;
      justify-content: space-between;
      margin-block: 20px;
      p,
      a {
        color: #ffffff;
      }
    }
  }
`;

export default StyledLoginPage;
