import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Spin, Steps, Radio } from "antd";
import styled from "styled-components";
import { getProfileData, toggleSpinner, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import CustomSelect from "../../components/CustomSelect";
import {
    toggleSpinnerSelector,
    profileSelector,
    editCharityProfileSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const countries = [
    { label: "Afghanistan", value: "AF" },
    { label: "Aland Islands", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua And Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia And Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, Democratic Republic", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Cote D'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island & Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle Of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea", value: "KR" },
    { label: "North Korea", value: "KP" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People's Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia", value: "FM" },
    { label: "Moldova", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Reunion", value: "RE" },
    { label: "Saint Barthelemy", value: "BL" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts And Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Martin", value: "MF" },
    { label: "Saint Pierre And Miquelon", value: "PM" },
    { label: "Saint Vincent And The Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome And Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Sint Maarten", value: "SX" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia And The South Sandwich Islands", value: "GS" },
    { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard And Jan Mayen", value: "SJ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad And Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" }
];

const CharityProfileLocations = ({ className }) => {
    const baseClassName = clsx("charityProfileLocations", className);
    const intl = useIntl();
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID = '', legalName, operatingLocation, nationallyActive } = {} } = useAppSelector(profileSelector) || {};

    const { message } = useAppSelector(editCharityProfileSelector) || {};

    useEffect(() => {
        if ((message) && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/profile/create/description");
        }
    }, [message])

    useEffect(() => {
        if (legalName) {
            const data = {
                ...(operatingLocation && { operatingLocation }),
                ...(nationallyActive && { radioGroup: nationallyActive === 'Y' ? 'nationallyActive' : 'locallyActive' }),
            };
            form.setFieldsValue(data);
        }
    }, [legalName]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            ID,
            ...formData,
            nationallyActive: formData?.radioGroup === 'nationallyActive' ? 'Y' : 'N',
            locallyActive: formData?.radioGroup === 'locallyActive' ? 'Y' : 'N',
        }
        dispatch(editCharityProfile(data))
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/profile/create/trustees")
    }

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "entity_details" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "locations" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "contact_details" })}
                        </StyledText>,
                    }
                ]}
            />
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "locations" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "where_are_you_able_to_run_your_projects" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "based_on_your_selections_we_will_be_able_to_recommend_relevant_new_funding_programmes_to_you" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        className="formContainer"
                        scrollToFirstError
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <Form.Item
                                    name="operatingLocation"
                                    rules={[{ required: true, message: 'Please select an option!' }]}
                                >
                                    <CustomSelect
                                        type="formSelect"
                                        name="category1"
                                        placeholder={intl.formatMessage({ id: "countries_of_operation" })}
                                        options={countries}
                                        mode="multiple"
                                    />
                                </Form.Item>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "within_your_selected_countries_can_you_run_projects_nationwide_or_in_specific_locations_only" })}
                                </StyledText>
                                <Form.Item
                                    name="radioGroup"
                                    rules={[{ required: true, message: 'Please select an option!' }]}
                                >
                                    <Radio.Group className='radioGroup'>
                                        <Radio value="nationallyActive">{intl.formatMessage({ id: "nationwide" })}</Radio>
                                        <Radio value="locallyActive">{intl.formatMessage({ id: "specific_locations_within_each_country" })}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="buttonContainer">
                                <Divider />
                                <div className="buttonContentContainer">
                                    <div className="nextAndPreviousContainer">
                                        <Button variant="secondary" type="button" onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button variant="primary" htmlType="submit">
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button variant="secondary" htmlType="button" onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

const StyledCharityProfileLocations = styled(CharityProfileLocations)`
&.charityProfileLocations{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .googlePlaceInputItem{
        width: 16vw;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .radioGroup {
        display: flex;
        flex-direction: column;
    }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100vh;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCharityProfileLocations;
