import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Steps, Avatar, Spin, Upload, Button as AntButton } from "antd";
import Config from "../../config"
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import ImgCrop from 'antd-img-crop';
import LinkedinIcon from "../../assets/linkedin.png";
import InstaGramIcon from "../../assets/instagram.png";
import TwitterIcon from "../../assets/twitter.png";
import FacebookIcon from "../../assets/facebook.png";
import styled from "styled-components";
import { uploadFile, get, onFileRemovedHandler, processPicture } from "../../utils/utilities";
import { getProfileData, toggleSpinner, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import {
    toggleSpinnerSelector,
    profileSelector,
    editCharityProfileSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import CustomModal from "../../components/Modal";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const CharityProfileLocations = ({ className }) => {
    const baseClassName = clsx("charityProfileLocations", className);
    const intl = useIntl();
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [processedLogoS3URL, setProcessedLogoS3URL] = useState([]);
    const [picture, setPicture] = useState([]);
    const [pictureData, setPictureData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID = '', legalName,
        socialMedia: {
            linkedInURL,
            facebookURL,
            instagramURL,
            xURL,
            twitterURL
        } = {},
        webURI,
        logoS3URL } = {} } = useAppSelector(profileSelector) || {};

    const { message } = useAppSelector(editCharityProfileSelector) || {};

    useEffect(() => {
        if ((message) && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/profile/create/preview");
        }
    }, [message])

    useEffect(() => {
        if (picture?.length) {
            const fetchProcessedLogoS3URL = async () => {
                const processedFiles = await processPicture(picture);
                setProcessedLogoS3URL([{ ...processedFiles?.[0], key: typeof pictureData === 'string' ? pictureData : undefined }])
            };
            fetchProcessedLogoS3URL();
        }
    }, [picture]);

    useEffect(() => {
        if (legalName) {
            const data = {
                ...(linkedInURL && { linkedInURL: linkedInURL }),
                ...(facebookURL && { facebookURL: facebookURL }),
                ...(instagramURL && { instagramURL: instagramURL }),
                ...(xURL && { xURL: xURL || twitterURL }),
                ...(twitterURL && { twitterURL: twitterURL }),
                ...(webURI && { webURI: webURI }),
            };
            form.setFieldsValue(data);
            setProcessedLogoS3URL(logoS3URL)
        }
    }, [legalName]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            ID,
            socialMedia: {
                linkedInURL: formData?.linkedInURL,
                facebookURL: formData?.facebookURL,
                instagramURL: formData?.instagramURL,
                xURL: formData?.xURL || formData?.twitterURL,
                twitterURL: formData?.twitterURL,
            },
            webURI: formData?.webURI,
            logoS3URL: processedLogoS3URL
        }
        dispatch(editCharityProfile(data))
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadFileToS3 = async (presignedPostData, file) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },

            };
            setPictureData(presignedPostData?.fields?.key)
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });

            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const getPresignedPostData = async (selectedFile) =>
        new Promise((resolve, reject) => {
            const fileName = (selectedFile.name).replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
            const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
            get(url, (res, err) => {
                if (res && res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(err.response);
                }
            });
        });

    const processFileUpload = async ({ onSuccess, onError, file }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file);
            onSuccess(null, file);
        } catch (e) {
            onError(e);
        }
    };

    const handlePrevious = () => {
        navigate("/impact-maker/profile/create/description")
    }

    const afterFileRemoval = () => {
        setPicture([]);
        setIsModalOpen(false)
    }

    const onDelete = () => {
        const data = processedLogoS3URL?.[0]?.key || pictureData
        onFileRemovedHandler(data, afterFileRemoval)
    }

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList?.length === 0) {
            setIsModalOpen(true)
            return
        }
        setPicture(newFileList)
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={3}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "entity_details" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "locations" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "contact_details" })}
                        </StyledText>,
                    }
                ]}
            />
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "media" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "please_share_a_link_to_your_website" })}
                                    <StyledText as="span" variant="B3a">
                                        {` (${intl.formatMessage({ id: "optional" })})`}
                                    </StyledText>
                                </StyledText>
                                <Form.Item
                                    name="webURI"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                                </Form.Item>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "if_applicable_please_share_your_social_media_links" })}
                                    <StyledText as="span" variant="B3a">
                                        {` (${intl.formatMessage({ id: "optional" })})`}
                                    </StyledText>
                                </StyledText>
                                <div className='linkContainer'>
                                    <Avatar src={LinkedinIcon} />
                                    <Form.Item
                                        name="linkedInURL"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "enter" }),
                                            },
                                        ]}
                                    >
                                        <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                                    </Form.Item>
                                </div>
                                <div className='linkContainer'>
                                    <Avatar src={FacebookIcon} />
                                    <Form.Item
                                        name="facebookURL"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "enter" }),
                                            },
                                        ]}
                                    >
                                        <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                                    </Form.Item>
                                </div>
                                <div className='linkContainer'>
                                    <Avatar src={InstaGramIcon} />
                                    <Form.Item
                                        name="instagramURL"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "enter" }),
                                            },
                                        ]}
                                    >
                                        <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                                    </Form.Item>
                                </div>
                                <div className='linkContainer'>
                                    <Avatar src={TwitterIcon} />
                                    <Form.Item
                                        name="twitterURL"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "enter" }),
                                            },
                                        ]}
                                    >
                                        <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                                    </Form.Item>
                                </div>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "please_add_your_entity_logo" })}
                                </StyledText>

                                <ImgCrop rotationSlider>
                                    <Upload
                                        maxCount={1}
                                        listType="picture-circle"
                                        onChange={onChange}
                                        fileList={processedLogoS3URL}
                                        onPreview={onPreview}
                                        customRequest={({
                                            onSuccess, onError, file, onProgress
                                        }) => processFileUpload({
                                            onSuccess, onError, file, onProgress
                                        })}
                                    >
                                        {processedLogoS3URL?.length < 1 && `+ ${intl.formatMessage({ id: "upload" })}`}
                                    </Upload>
                                </ImgCrop>
                                <CustomModal
                                    title={intl.formatMessage({ id: 'delete_file_confirmation' })}
                                    open={isModalOpen}
                                    onOk={() => setIsModalOpen(false)}
                                    onCancel={() => setIsModalOpen(false)}
                                    closable={false}
                                    footer={null}>
                                    <>
                                        <StyledText as="p" variant="B3" className="title">
                                            {intl.formatMessage({ id: 'please_confirm_you_are_happy_to_permanently_delete_this_file' })}
                                        </StyledText>
                                        <div>
                                            <div className='modalFooterButton'>
                                                <Button
                                                    variant="secondary"
                                                    htmlType="button"
                                                    onClick={() => setIsModalOpen(false)}>
                                                    {intl.formatMessage({ id: "cancel" })}
                                                </Button>
                                                <AntButton
                                                    variant="primary"
                                                    htmlType="button"
                                                    className='deleteButton'
                                                    onClick={() => onDelete()}>
                                                    {intl.formatMessage({ id: "delete" })}
                                                </AntButton>
                                            </div>
                                        </div>
                                    </>
                                </CustomModal>
                            </div>
                            <div className='buttonContainer'>
                                <Divider />
                                <div className='buttonContentContainer'>
                                    <div className='nextAndPreviousContainer'>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            htmlType="submit">
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button
                                            variant="secondary"
                                            htmlType="button"
                                            onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCharityProfileLocations = styled(CharityProfileLocations)`
&.charityProfileLocations{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .formContainer {
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 83vh;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
            display: grid;
            gap: 20px;
            .formItem {
                width: 450px;
                margin-bottom: 0;
            }
        }
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
            }
        }
    }
    .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
}`;

export default StyledCharityProfileLocations;
