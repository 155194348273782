import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Checkbox, Spin, Steps, Radio, Upload, Avatar } from "antd";
import styled from "styled-components";
import { uploadFile } from "../../utils/utilities";
import axios from '../../utils/axios';
import Config from '../../config';
import { getProfileData, toggleSpinner, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import lightbulb from '../../assets/lightbulb.svg'
import {
    toggleSpinnerSelector,
    profileSelector,
    editCharityProfileSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const entitiesSupportedOptions = [
    "Registered Charity",
    "Community Interest Company",
    "Charitable Company",
    "Charitable Trust",
    "Academic Institution",
    "Unregistered organisation",
    "Parish or Community Council",
    "Government Linked Entity",
    "For Profit Company"
];

const CharityProfileLegalStructure = ({ className }) => {
    const baseClassName = clsx("charityProfileLegalStructure", className);
    const intl = useIntl();
    const [pictures, setPictures] = useState([]);
    const [secondPictures, setSecondPictures] = useState([]);
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID, legalName, registrationProfileURL, registrationNumber, entityType: savedEntityType, documents } = {} } = useAppSelector(profileSelector) || {};

    const { message } = useAppSelector(editCharityProfileSelector) || {};

    useEffect(() => {
        if ((message) && !savedClicked) {
            dispatch(toggleSpinner(false));
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            navigate("/impact-maker/profile/create/trustees");
        }
    }, [message])

    useEffect(() => {
        if (legalName) {
            const data = {
                ...(savedEntityType && { entityType: savedEntityType }),
                ...(registrationProfileURL && { registrationProfileURL }),
                ...(registrationNumber && { registrationNumber }),
            };
            if (documents?.find((el) => el?.documentType === 'Governing Documents' && el?.na === 'Y' && el?.naReason)) {
                data["Governing Documents - na"] = true
                data["Governing Documents - naReason"] = documents?.find((el) => el?.documentType === 'Governing Documents' && el.na === 'Y')?.naReason
            }
            if (documents?.find((el) => el?.documentType === 'Annual Accounts' && el?.na === 'Y' && el?.naReason)) {
                data["Annual Accounts - na"] = true
                data["Annual Accounts - naReason"] = documents?.find((el) => el?.documentType === 'Governing Documents' && el.na === 'Y')?.naReason
            }

            if (documents?.find((el) => el?.documentType === 'Annual Accounts' && el?.key)) {
                const images = documents?.find((el) => el?.documentType === 'Annual Accounts' && el?.key)
                setSecondPictures([images])
            }
            if (documents?.find((el) => el?.documentType === 'Governing Documents' && el?.key)) {
                const images = documents?.find((el) => el?.documentType === 'Governing Documents' && el?.key)
                setPictures([images])
            }
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [legalName]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    function updateStatusIfTrue(obj) {
        const filteredEntries = Object.entries(obj)
            .filter(([key, value]) => !key.toLowerCase().includes('upload') && value !== undefined);

        const filteredObj = Object.fromEntries(filteredEntries);
        for (const [key, value] of Object.entries(filteredObj)) {
            if (value === true) {
                filteredObj[key] = 'Y';
            }
        }

        return filteredObj;
    }

    function transformDocuments(input) {
        if (!input || typeof input !== 'object') {
            return
        }
        const documents = [];
        const otherFields = {};
        const regex = /^([^ -]+(?: [^ -]+)*) - (na|naReason)$/;

        Object.entries(input).forEach(([key, value]) => {
            if (!key || typeof key !== 'string') {
                return;
            }

            const match = regex.exec(key);

            if (match) {
                const documentType = match[1];
                const fieldType = match[2];

                if (!documentType || !fieldType) {
                    return;
                }

                let document = documents.find(doc => doc.documentType === documentType);

                if (!document) {
                    document = {
                        documentType,
                        na: undefined,
                        naReason: undefined
                    };
                    documents.push(document);
                }

                if (fieldType === 'na') {
                    document.na = value ?? null;
                } else if (fieldType === 'naReason') {
                    document.naReason = value ?? null;
                }
            } else {
                otherFields[key] = value ?? null;
            }
        });

        return { documents, ...otherFields };
    }

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const updatedData = updateStatusIfTrue(formData);
        const { documents, ...rest } = transformDocuments(updatedData);
        if (pictures?.length > 0) {
            documents.push(...pictures)
        }
        if (secondPictures?.length > 0) {
            documents.push(...secondPictures)
        }
        const finalData = {
            ID,
            documents: documents,
            ...rest,
        }

        console.log("updatedData", finalData)

        dispatch(editCharityProfile(finalData))
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/profile/create/entity-name")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const onSecondChange = ({ fileList: newFileList }) => {
        setSecondPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const onChange = ({ fileList: newFileList }) => {
        setPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const processSecondFileUpload = async ({ onSuccess, onError, file, documentType }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file);
            onSuccess(null, file);
            const { key } = presignedPostData.fields || {};
            setSecondPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, key, documentType } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };

    const processFileUpload = async ({ onSuccess, onError, file, documentType }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file);
            onSuccess(null, file);
            const { key } = presignedPostData.fields || {};
            setPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, key, documentType } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };

    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const onPreview = async (file) => {
        let src = file.url;
        if (!src && file?.originFileObj instanceof Blob) {
            src = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Failed to read file'));
            });
        }

        if (src) {
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else {
            console.error('Failed to preview image: Source is invalid or unavailable.');
        }
    };

    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onSecondDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onSecondRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onSecondDelete(file), data, true);
    };

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    const { entityType } = formData || {};

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={1}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "entity_details" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "locations" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "contact_details" })}
                        </StyledText>,
                    }
                ]}
            />
            <div className='topContainer'>
                <div className='leftContainer'>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "legal_structure" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "what_is_the_legal_Name_of_your_entity" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <Form.Item
                                    name="entityType"
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: "enter" }) }
                                    ]}
                                >
                                    <Radio.Group className="radioGroup">
                                        {entitiesSupportedOptions?.map((el) => <Radio key={el} className="radio" value={el}>
                                            <StyledText as="p" variant="B3">
                                                {el}
                                            </StyledText>
                                        </Radio>)}
                                    </Radio.Group>
                                </Form.Item>
                                {["Registered Charity", "Community Interest Company", "Charitable Company"].includes(entityType) && (
                                    <>
                                        <StyledText as="span" variant="B3d">
                                            {intl.formatMessage({ id: "please_upload_a_copy_of_your_governing_document" })}
                                        </StyledText>
                                        <Form.Item
                                            className="uploadWrapper"
                                            name="governingDocumentUpload"
                                            rules={[
                                                {
                                                    required: !formData?.["Governing Documents - na"],
                                                    message: intl.formatMessage({ id: "please_upload_documentation" }),
                                                },
                                            ]}
                                        >
                                            <Upload
                                                listType="picture-card"
                                                onChange={onChange}
                                                fileList={pictures}
                                                onPreview={onPreview}
                                                onRemove={onRemove}
                                                customRequest={({ onSuccess, onError, file }) =>
                                                    processFileUpload({
                                                        onSuccess,
                                                        onError,
                                                        file,
                                                        documentType: "Governing Documents",
                                                    })
                                                }
                                                multiple
                                            >
                                                {`+ ${intl.formatMessage({ id: "upload" })}`}
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item
                                            name="Governing Documents - na"
                                            valuePropName="checked"
                                        >
                                            <Checkbox
                                            >
                                                <StyledText as="span" variant="B3">
                                                    {intl.formatMessage({ id: "these_are_not_available" })}
                                                </StyledText>
                                            </Checkbox>
                                        </Form.Item>
                                        {formData?.["Governing Documents - na"] && (
                                            <Form.Item
                                                className="dFlex marginBottom"
                                                name="Governing Documents - naReason"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_share_a_reason" }),
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    className="textContent"
                                                    maxLength={300}
                                                    placeholder={intl.formatMessage({ id: "please_share_a_reason" })}
                                                    rows={2}
                                                />
                                            </Form.Item>
                                        )}
                                        <StyledText as="span" variant="B3d">
                                            {intl.formatMessage({ id: "please_upload_a_copy_of_your_most_recent_annual_accounts" })}
                                        </StyledText>
                                        <Form.Item
                                            className="uploadWrapper"
                                            name="annualAccountsUpload"
                                            rules={[
                                                {
                                                    required: !formData?.["Annual Accounts - na"],
                                                    message: intl.formatMessage({ id: "please_upload_documentation" }),
                                                },
                                            ]}
                                        >
                                            <Upload
                                                listType="picture-card"
                                                onChange={onSecondChange}
                                                fileList={secondPictures}
                                                onPreview={onPreview}
                                                onRemove={onSecondRemove}
                                                customRequest={({ onSuccess, onError, file }) =>
                                                    processSecondFileUpload({
                                                        onSuccess,
                                                        onError,
                                                        file,
                                                        documentType: "Annual Accounts",
                                                    })
                                                }
                                                multiple
                                            >
                                                {`+ ${intl.formatMessage({ id: "upload" })}`}
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item
                                            name="Annual Accounts - na"
                                            valuePropName="checked"
                                        >
                                            <Checkbox
                                            >
                                                <StyledText as="span" variant="B3">
                                                    {intl.formatMessage({ id: "these_are_not_available" })}
                                                </StyledText>
                                            </Checkbox>
                                        </Form.Item>
                                        {formData?.["Annual Accounts - na"] && (
                                            <Form.Item
                                                className="dFlex marginBottom"
                                                name="Annual Accounts - naReason"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_share_a_reason" }),
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    className="textContent"
                                                    maxLength={300}
                                                    placeholder={intl.formatMessage({ id: "please_share_a_reason" })}
                                                    rows={2}
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )}
                                {["Charitable Trust"].includes(entityType) && <>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_upload_a_copy_of_your_governing_document" })}
                                    </StyledText>
                                    <Form.Item className='uploadWrapper'
                                        name="governingDocumentUpload"
                                        rules={[
                                            {
                                                required: !formData?.["Governing Documents - na"],
                                                message: intl.formatMessage({ id: "please_upload_documentation" }),
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            onChange={onChange}
                                            fileList={pictures}
                                            onPreview={onPreview}
                                            onRemove={onRemove}
                                            customRequest={({
                                                onSuccess, onError, file
                                            }) => processFileUpload({
                                                onSuccess, onError, file, documentType: "Governing Documents"
                                            })}
                                            multiple
                                        >
                                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="Governing Documents - na"
                                        valuePropName="checked"
                                        rules={[{ required: false }]}
                                    >
                                        <Checkbox value="Y">
                                            <StyledText as="span" variant="B3" >
                                                {intl.formatMessage({ id: "these_are_not_available" })}
                                            </StyledText>
                                        </Checkbox>
                                    </Form.Item>
                                    {formData?.["Governing Documents - na"] &&
                                        <Form.Item
                                            className="dFlex marginBottom"
                                            name="Governing Documents - naReason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'please_share_a_reason',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="textContent"
                                                maxLength="300"
                                                placeholder={intl.formatMessage({
                                                    id: 'please_share_a_reason',
                                                })}
                                                rows={2}
                                            />
                                        </Form.Item>
                                    }
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_upload_a_copy_of_your_most_recent_annual_accounts" })}
                                    </StyledText>
                                    <Form.Item className='uploadWrapper' name="annualAccountsUpload" rules={[
                                        {
                                            required: !formData?.["Annual Accounts - na"],
                                            message: intl.formatMessage({ id: "please_upload_documentation" }),
                                        },
                                    ]}>
                                        <Upload
                                            listType="picture-card"
                                            onChange={onSecondChange}
                                            fileList={secondPictures}
                                            onPreview={onPreview}
                                            onRemove={onSecondRemove}
                                            customRequest={({
                                                onSuccess, onError, file
                                            }) => processSecondFileUpload({
                                                onSuccess, onError, file, documentType: "Annual Accounts"
                                            })}
                                            multiple
                                        >
                                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="Annual Accounts - na"
                                        valuePropName="checked"
                                        rules={[{ required: false }]}
                                    >
                                        <Checkbox value="Y">
                                            <StyledText as="span" variant="B3" >
                                                {intl.formatMessage({ id: "these_are_not_available" })}
                                            </StyledText>
                                        </Checkbox>
                                    </Form.Item>
                                    {formData?.["Annual Accounts - na"] &&
                                        <Form.Item
                                            className="dFlex marginBottom"
                                            name="Annual Accounts - naReason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'please_share_a_reason',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="textContent"
                                                maxLength="300"
                                                placeholder={intl.formatMessage({
                                                    id: 'please_share_a_reason',
                                                })}
                                                rows={2}
                                            />
                                        </Form.Item>
                                    }
                                </>}
                                {["Academic Institution"].includes(entityType) && <>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_upload_a_copy_of_your_governing_document" })}
                                    </StyledText>
                                    <StyledText as="span" variant="B3">
                                        {intl.formatMessage({ id: "for_example_your_Articles_of_association" })}
                                    </StyledText>
                                    <Form.Item className='uploadWrapper'
                                        name="governingDocumentUpload"
                                        rules={[
                                            {
                                                required: !formData?.["Governing Documents - na"],
                                                message: intl.formatMessage({ id: "please_upload_documentation" }),
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            onChange={onChange}
                                            fileList={pictures}
                                            onPreview={onPreview}
                                            onRemove={onRemove}
                                            customRequest={({
                                                onSuccess, onError, file
                                            }) => processFileUpload({
                                                onSuccess, onError, file, documentType: "Governing Documents"
                                            })}
                                            multiple
                                        >
                                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="Governing Documents - na"
                                        valuePropName="checked"
                                        rules={[{ required: false }]}
                                    >
                                        <Checkbox value="Y">
                                            <StyledText as="span" variant="B3" >
                                                {intl.formatMessage({ id: "these_are_not_available" })}
                                            </StyledText>
                                        </Checkbox>
                                    </Form.Item>
                                    {formData?.["Governing Documents - na"] &&
                                        <Form.Item
                                            className="dFlex marginBottom"
                                            name="Governing Documents - naReason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'please_share_a_reason',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="textContent"
                                                maxLength="300"
                                                placeholder={intl.formatMessage({
                                                    id: 'please_share_a_reason',
                                                })}
                                                rows={2}
                                            />
                                        </Form.Item>
                                    }
                                </>}
                                {["Unregistered organisation"].includes(entityType) && <>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_upload_a_copy_of_your_constitution_trust_deed_or_rules" })}
                                    </StyledText>
                                    <StyledText as="span" variant="B3">
                                        {intl.formatMessage({ id: "for_example_your_Articles_of_association" })}
                                    </StyledText>
                                    <Form.Item className='uploadWrapper'
                                        name="governingDocumentUpload"
                                        rules={[
                                            {
                                                required: !formData?.["Governing Documents - na"],
                                                message: intl.formatMessage({ id: "please_upload_documentation" }),
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            onChange={onChange}
                                            fileList={pictures}
                                            onPreview={onPreview}
                                            onRemove={onRemove}
                                            customRequest={({
                                                onSuccess, onError, file
                                            }) => processFileUpload({
                                                onSuccess, onError, file, documentType: "Documents"
                                            })}
                                            multiple
                                        >
                                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="Governing Documents - na"
                                        valuePropName="checked"
                                        rules={[{ required: false }]}
                                    >
                                        <Checkbox value="Y">
                                            <StyledText as="span" variant="B3" >
                                                {intl.formatMessage({ id: "these_are_not_available" })}
                                            </StyledText>
                                        </Checkbox>
                                    </Form.Item>
                                    {formData?.["Governing Documents - na"] &&
                                        <Form.Item
                                            className="dFlex marginBottom"
                                            name="Governing Documents - naReason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'please_share_a_reason',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="textContent"
                                                maxLength="300"
                                                placeholder={intl.formatMessage({
                                                    id: 'please_share_a_reason',
                                                })}
                                                rows={2}
                                            />
                                        </Form.Item>
                                    }
                                </>}
                                {["Parish or Community Council", "Government Linked Entity"].includes(entityType) && <>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_upload_a_copy_of_your_registration_documents_or_most_recent_annual_accounts" })}
                                    </StyledText>
                                    <Form.Item className='uploadWrapper'
                                        name="governingDocumentUpload"
                                        rules={[
                                            {
                                                required: !formData?.["Governing Documents - na"],
                                                message: intl.formatMessage({ id: "please_upload_documentation" }),
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            onChange={onChange}
                                            fileList={pictures}
                                            onPreview={onPreview}
                                            onRemove={onRemove}
                                            customRequest={({
                                                onSuccess, onError, file
                                            }) => processFileUpload({
                                                onSuccess, onError, file, documentType: "Other Documents"
                                            })}
                                            multiple
                                        >
                                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name="Governing Documents - na"
                                        valuePropName="checked"
                                        rules={[{ required: false }]}
                                    >
                                        <Checkbox value="Y">
                                            <StyledText as="span" variant="B3" >
                                                {intl.formatMessage({ id: "these_are_not_available" })}
                                            </StyledText>
                                        </Checkbox>
                                    </Form.Item>
                                    {formData?.["Governing Documents - na"] &&
                                        <Form.Item
                                            className="dFlex marginBottom"
                                            name="Governing Documents - naReason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'please_share_a_reason',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="textContent"
                                                maxLength="300"
                                                placeholder={intl.formatMessage({
                                                    id: 'please_share_a_reason',
                                                })}
                                                rows={2}
                                            />
                                        </Form.Item>
                                    }
                                </>}
                                {["For Profit Company"].includes(entityType) && <>
                                    <StyledText as="p" variant="B3d">
                                        {intl.formatMessage({ id: "what_is_your_registration_number" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "this_is_your_registration_number_with_the_relevant_regulator_of_your_entity" })}
                                    </StyledText>
                                    <Form.Item
                                        name="registrationNumber"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "enter" }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: "enter",
                                            })}
                                        />
                                    </Form.Item>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "please_share_a_link_to_your_online_registration" })}
                                        <StyledText as="span" variant="B3a">
                                            {` (${intl.formatMessage({ id: "optional" })})`}
                                        </StyledText>
                                    </StyledText>
                                    <Form.Item
                                        name="registrationProfileURL"
                                        className="formItem"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: false,
                                                message: intl.formatMessage({ id: "enter_url" }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: "enter_url",
                                            })}
                                        />
                                    </Form.Item>
                                </>}
                            </div>
                            <div className='buttonContainer'>
                                <Divider />
                                <div className='buttonContentContainer'>
                                    <div className='nextAndPreviousContainer'>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            htmlType="submit"
                                        >
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button
                                            variant="secondary"
                                            htmlType="button"
                                            onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "entity_types" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "registered_charity" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "registered_with_the_relevant_national_or_regional_regulatory_authority_for_example_the_charity_commission_in_the_uk" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "community_interest_company_uk_only" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "registered_with_companies_house" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "charitable_trust" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "a_type_of_charity_run_by_a_small_group_of_people_known_as_trustees" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "academic_institution" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_educational_institution_such_as_a_university_or_school" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "unregistered_organisation" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_entity_that_is_not_registered_or_regulated_for_example_clubs_societies_and_small_community_groups" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "charitable_company" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "a_type_of_charitable_organisation_registered_as_a_company_limited_by_guarantee" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {`${intl.formatMessage({ id: "council_or_government_linked_entity" })}:`}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "organisations_that_are_responsible_for_the_provision_of_public_services" })}
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledCharityProfileLegalStructure = styled(CharityProfileLegalStructure)`
&.charityProfileLegalStructure{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .googlePlaceInputItem{
        width: 16vw;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .topContainer{
        display: flex;
        justify-content: space-between;
    }
    .leftContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 200vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCharityProfileLegalStructure;