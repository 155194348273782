const enLocale = {
  'date.format': '{date, date, long}',
  test: `Test locale`,
  dashboard: `Dashboard`,
  engage: `Engage`,
  assets: `Assets`,
  submissions: `Submissions`,
  impact: `Impact`,
  programmes: `Programmes`,
  programme: `Programme`,
  interactions: `Interactions`,
  donations: `Donations`,
  content: `Content`,
  impact_strategy: `Impact Strategy`,
  my_profile: `My Profile`,
  organization: `Organization`,
  users: `Users`,
  profile: `Profile`,
  help: `Help`,
  logout: `Logout`,
  insights: `Insights`,
  data: `Data`,
  progress: `Progress`,
  ideas: `Ideas`,
  reviewed: `Reviewed`,
  in_progress: `In Progress`,
  pending_review: `Pending Review`,
  most_popular_theme: `Most Popular Theme`,
  local_resident: `Local Resident`,
  local_business: `Local Business`,
  charitable_group: `Charitable Group`,
  other_stakeholder: `Other Stakeholder`,
  cultural: `Cultural`,
  environmental: `Environmental`,
  economic: `Economic`,
  educational: `Educational`,
  social: `Social`,
  responder_types: `Responder Types`,
  most_popular_category: `Most Popular Category`,
  green_spaces: `Green Spaces`,
  local_tourism: `Local Tourism`,
  skills_development: `Skills Development`,
  other: `Other`,
  most_popular_sub_category: `Most Popular Sub Category`,
  grassland: `Grassland`,
  burgers: `Burgers`,
  green_skills: `Green skills`,
  filters: `Filters`,
  theme: `Theme`,
  category: `Category`,
  sub_category: `Sub-category`,
  status: `Status`,
  ideas_by_phase: `Ideas By Phase`,
  oops: `Oops! Username/Password might be incorrect`,
  forgot_password: `Forgot Password?`,
  forgot_password_line1: `If you have an account, you will have received a ‘Reset Password’  email from BizGive Support. Follow the link in that email to create a new password.`,
  forgot_password_line2: `Please also check your spam folder.`,
  forgot_password_line3: `If you do not receive an email you may not have an active account. Please contact BizGive Support.`,
  forgot_password_header: `Please check your inbox`,
  incorrect_email_id: `Please check the email id you have entered.`,
  forget_password: `Forget Password`,
  forget_password_hint: `Please use over 8 characters, an upper and lower case letter, a number and a special character.`,
  date_submitted: `Date Submitted`,
  need: `Need`,
  attributes: `Attributes`,
  happy_to_be_contacted: `Happy to be contacted?`,
  actions: `Actions`,
  add_comment: `Add Comment`,
  date_actioned: `Date Actioned`,
  actioned_by: `Actioned By`,
  back_to_map: `Back to map`,
  submitter_type: `Submitter Type`,
  submitter_location: `Submitter Location`,
  submission_location: `Submission Location`,
  categories: `Categories`,
  yes: `Yes`,
  no: `No`,
  save: `Save`,
  action: `Action`,
  idea_location: `Idea Location`,
  please_populate: `Please populate`,
  comments_added_successfully: `Comments added successfully`,
  see_location: `See Location`,
  feedback: `Feedback`,
  feedback_by_phase: `Feedback by Phase`,
  feedback_location: `Feedback Location`,
  comment: `Comment`,
  asset: `Asset`,
  phase: `Phase`,
  idea: `Idea`,
  submitter: `Submitter`,
  requestedContact: `Requested Contact?`,
  collaborate: `Collaborate`,
  contact_by_phase: `Contact by Phase`,
  collaborate_location: `Collaborate Location`,
  number_of_employees: `No. Employees`,
  global_annual_turnover: `Global Annual Turnover (£m)`,
  industry: `Industry`,
  services_provided: `Services Provided`,
  services_description: `Services Description`,
  renewable_experience: `Renewable Experience?`,
  requested_introduction_to_tier_one_contractors: `Requested introduction to Tier 1 Contractors?`,
  organization_name: `Organization Name`,
  website: `Website`,
  overview: `Overview`,
  total_visitors: `Total Visitors`,
  feedback_submitted: `Feedback submitted`,
  ideas_submitted: `Ideas submitted`,
  collaborations_submitted: `Collaborations submitted`,
  pending_actions: `Pending Actions`,
  three_month_historical_visitors: `3 Month Historical Visitors`,
  submission_type: `Submission Type`,
  collaborations: `Collaborations`,
  applications: `Applications`,
  action_required: `Action Required`,
  pending_response: `Pending Response`,
  pre_approved: `Pre Approved`,
  approved: `Approved`,
  declined: `Declined`,
  your_session_will_expire_in: `Your session will expire in…`,
  select_whether_you_would_like_to_stay_logged_in_or_log_out: `Select whether you would like to stay logged in or log out. If you do nothing you will be automatically logged out for security reasons.`,
  stay_logged_in: `Stay Logged In`,
  log_out: `Log Out`,
  all_phases: `All phases`,
  login: `Login`,
  not_registered_yet: `Not registered yet?`,
  sign_up: `Sign Up`,
  user_terms: `User Terms`,
  privacy_policy: `Privacy Policy`,
  create_an_account_to_apply_for_support: `Create an account to apply for support`,
  are_you_impact_funder_contact_us: `Are you an impact funder? Contact us`,
  confirm_password: `Confirm Password`,
  password_must_include: `Password must include:`,
  more_than_eight_characters: `- More than 8 characters`,
  an_upper_and_lower_case_character: `- An upper and lower case character`,
  a_number: `- A number`,
  a_special_character: `- A special character`,
  i_accept_the: `I accept the`,
  register: `Register`,
  connect_with_funders_and_design_your_opportunities_to_create_positive_impact: `Connect with funders and design your opportunities to create positive impact`,
  or_login_to_create_your_free_BizGive_account: `or login to create your free BizGive account.`,
  discover: `Discover`,
  funding_programmes_and_create_a_profile_to_receive_alerts: `funding programmes and create a profile to receive alerts.`,
  submit: `Submit`,
  applications_auto_verified_against_funder_criteria: `applications, auto-verified against funder criteria.`,
  share: `Share`,
  content_track_impact_and_capture_funder_feedback: `content, track impact and capture funder feedback.`,
  here: `here`,
  please_enter_password: `Please enter Password`,
  ok: `Ok`,
  confirm_email: `Confirm Email`,
  log_in: `Log in`,
  please_enter_email: `Please enter Email`,
  the_input_is_not_valid_email: `The input is not valid E-mail!`,
  the_new_password_that_you_entered_do_not_match: `The new password that you entered do not match!`,
  the_new_email_that_you_entered_do_not_match: `The new email that you entered do not match!`,
  please_confirm_your_password: `Please enter Confirm Password`,
  please_accept_the_user_terms: `Please accept the User Terms`,
  change_password: `Change Password`,
  password: `Password`,
  we_just_emailed: `We just emailed`,
  please_click_on_the_link_in_your_email_to_activate_your_account: `Please click on the link in your email to activate your account`,
  please_enter_a_valid_password: `Please enter a valid password`,
  you_have_been_logged_out_due_to_inactivity: `You’ve been logged out due to inactivity...`,
  please_enter_a_valid_password_using_the_guidance_below: `Error - Please enter a valid password using the guidance below.`,
  entity: `Entity`,
  media: `Media`,
  logo: `Logo`,
  profile_create: `Profile Create`,
  lets_get_started: `Let’s get started`,
  name_and_location: `Name and location`,
  what_is_the_legal_Name_of_your_entity: `What is the Legal Name of your entity?`,
  what_entity_name_would_like_to_be_displayed: `What entity name would like to be displayed?`,
  what_is_your_registered_address: `What is your registered address?`,
  description: `Description`,
  add_your_description: `Add your description`,
  add_your_social_media_details: `Add your social media details`,
  what_is_your_website: `What is your website?`,
  optional: `Optional`,
  add_your_company_logo: `Add your company logo`,
  upload: `Upload`,
  organization_profile: `Organization Profile`,
  detail: `Detail`,
  registered_address: `Registered Address`,
  proceed: `Proceed`,
  previous: `Previous`,
  save_n_exit: `Save & Exit`,
  next: `Next`,
  enter: `Enter`,
  complete: `Complete`,
  my_details: `My Details`,
  your_name: `Your name`,
  phone_number: `Phone Number`,
  job_title: `Job Title`,
  add_your_profile_picture: `Add your profile picture`,
  biz_give_role: `BizGive Role`,
  member_since: `Member since`,
  my_programmes: `My Programmes`,
  delete_file_confirmation: `Delete File Confirmation`,
  please_confirm_you_are_happy_to_permanently_delete_this_file: `Please confirm you are happy to permanently delete this file?`,
  delete: `Delete`,
  cancel: `Cancel`,
  create_password: `Create Password`,
  invite_new_user: `Invite New User`,
  regular_users: `Regular Users`,
  can_not_view_or_edit_anything_by_default: `can not view or edit anything by default. All invited users start with this status. You can add them to Programmes to view and or edit that specific programme and related content.`,
  organization_admins: `Organization Admins`,
  can_access_and_edit_everything_in_the_platform: `can access and edit everything in the platform. You can assign existing users with this privilege on this screen.`,
  user_name: `User Name`,
  make_admin: `Make Admin`,
  remove_admin: `Remove Admin`,
  invited: `INVITED`,
  regular: `REGULAR`,
  admin: `ADMIN`,
  remove_user_from_your_organization: `Remove user from your organization`,
  please_confirm_you_are_to_permanently_remove_this_user: `Please confirm you are to permanently remove this user?`,
  none_of_their_programmes_or_interaction_data_will_be_removed: `None of their programmes or interaction data will be removed`,
  remove_user: `Remove User`,
  remove_admin_rights: `Remove Admin Rights`,
  remove: `Remove`,
  as_an_Organization_Admin: `as an Organization Admin.`,
  they_will_then_only_be_able_to_view_and_edit_programmes_that_they_manage: `They will then only be able to view and edit programmes that they manage.`,
  enter_email_address: `Enter email address`,
  confirm_email_address: `Confirm email address`,
  invite: `Invite`,
  add_admin_rights: `Add Admin Rights`,
  make: `Make`,
  an_organization_admin: `an Organization Admin`,
  they_will_be_able_to_view_and_edit_everything_in_your_organization: `They will be able to view and edit everything in your organization.`,
  charity_can_not_view_or_edit_anything_by_default: `can not view or edit anything by default. All invited users start with this status. You can add them to applications to view and or edit that specific application and related content.`,
  charity_can_access_and_edit_everything_in_the_platform: `can access and edit everything in the platform. You can assign existing users with this privilege on this screen.`,
  none_of_their_applications_or_interaction_data_will_be_removed: `None of their applications or interaction data will be removed`,
  they_will_then_only_be_able_to_view_and_edit_applications_that_they_manage: `They will then only be able to view and edit applications that they manage.`,
  the_invited_user_already_has_an_account_linked_to_this_email_address: `The invited user already has an account linked to this email address. Please check your user list or contact BizGive for support.`,
  all_programmes: `All Programmes`,
  download: `Download`,
  search_placeholder: `Search...`,
  actions_required: `Actions Required`,
  opportunity: `Opportunity`,
  date_applied: `Date Applied`,
  date_requested: `Date Requested`,
  net_vote: `Net Vote (Voters)`,
  date_approved: `Date Approved`,
  date_declined: `Date Declined`,
  applied: `APPLIED`,
  in_edit: `IN EDIT`,
  pre_approved_caps: `PRE-APPROVED`,
  approved_caps: `APPROVED`,
  declined_caps: `DECLINED`,
  review: `Review`,
  customize_your_download: `Customize Your Download`,
  select_programme: `Select Programme`,
  select_application_status: `Select Application Status`,
  share_and_manage_voting: `Share and Manage Voting`,
  current_share_access_closing_date: `Current Share Access Closing Date`,
  invited_users_will_receive_an_email_with_access_details: `Invited Users will receive an email with access details, accessible up to the Closing Date. They will only be able to view their votes.`,
  invited_voters: `Invited Voters`,
  add_email_address: `Add email address`,
  reported_grants: `Reported Grants`,
  planned_grants: `Planned Grants`,
  remaining_budget: `Remaining Budget`,
  total_budget: `Total Budget`,
  do_you_wish_to_request_indicators: `Do you wish to request indicators?`,
  the_entity_will_be_asked_to_create: `The entity will be asked to create indicators for their opportunity aligned to selected outcomes.`,
  please_confirm_you_wish_to_complete_this_item: `This step is complete.`,
  requested: `Requested`,
  do_you_wish_the_applicant_to_create_indicators_to_measure_its_progress: `Do you wish the applicant to create indicators to measure its progress?`,
  volunteering: `Volunteering`,
  day: `Day`,
  inkind: `In-Kind`,
  budget_period_end: `Budget Period End`,
  budget_available: `Budget Available`,
  budget_amount: `Budget Amount`,
  remaining: `Remaining`,
  select_filter: `Please select a filter`,
  donation_title: `Donation`,
  email_id_title: `Email Id`,
  add_user: `Add User`,
  delete_title: `Delete`,
  add_title: `Add`,
  delete_user: `Delete User`,
  action_cannot_undone: `This action cannot be undone. Any Opportunities that they manage will still be accesible by account admins.`,
  confirm_title: `Confirm`,
  adding_admin_rights: `Adding admin rights, gives the ability for the user to create opportunities, update the organisation profile, add and remove users and create admins.`,
  remove_admin_rights_para: `Removing admin rights, removes the ability for the user to create opportunities, update the organisation profile, add and remove users and create admins.`,
  save_private: `Saved (Private)`,
  planned_shared: `Planned (Shared)`,
  invoice_requested: `Invoice Requested`,
  invoice_received: `Invoice Received`,
  reported_title: `Reported`,
  confirmed_title: `Confirmed`,
  report_donation: `Report donation`,
  cancel_title: `Cancel`,
  save_title: `Save`,
  grant: `Grant`,
  awarded: `Awarded`,
  award_title: `Award`,
  type_title: `Type`,
  invoice_title: `Invoice`,
  condition_title: `Condition`,
  reported_date_title: `Reported Date`,
  next_action_title: `Next Action`,
  donation_created: `Success - donation created`,
  donation_updated: `Success - donation updated`,
  donation_deleted: `Success - donation deleted`,
  view_donation: `View Donation`,
  select_title: `Select`,
  approved_opportunities_planned_impact: `Approved Opportunities (Planned Impact)`,
  achieved_impact: `Achieved Impact (Completed Opportunities)`,
  achieved_beneficiaries: `Achieved Beneficiaries`,
  outputs_achieved: `Outputs Achieved`,
  content_received: `Content Received (Achieved Impact)`,
  opportunity_name: `Opportunity Name`,
  entity_name: `Entity Name`,
  due_date: `Due Date`,
  number: `Number`,
  approved_date: `Approved Date`,
  view: `View`,
  primary_UN_SDG: `Primary UN SDG `,
  opportunity_type: `Opportunity Type`,
  charity_name: `Charity Name`,
  select_type: `Please select a type`,
  please_fill_amount: `Please fill amount`,
  enter_title: `Enter`,
  please_enter_conditions: `please enter Condition`,
  please_select_action: `Please select an action`,
  actions_title: `Actions`,
  select_action: `Select Action`,
  payment_reference: `Payment Reference`,
  please_select_amount: `Please select an amount`,
  enter_payment_reference: `Enter Payment Reference`,
  upload_counterpart_behalf: `Upload Invoice on behalf of counterpart`,
  equivalent_value: `Equivalent Value`,
  amount_title: `Amount`,
  purchase_order: `Purchase Order`,
  files_title: `Files`,
  company_name_title: `Company Name`,
  programme_type_title: `Programme Type`,
  application_start_date: `Application Start Date`,
  application_end_date: `Application End Date`,
  location_title: `Location`,
  next_update_outcome: `Next Update / Outcome`,
  donations_by: `Donations By`,
  decision_date: `Decision Date`,
  decision_start_date: `Decision Start Date`,
  decision_end_date: `Decision End Date`,
  opportunity_i_manage: `Opportunities I Manage`,
  select_template: `Select Template`,
  please_select_template: `Please select a template`,
  save_draft: `Save Draft`,
  all_opportunities: `All Opportunities`,
  excel_title: `Excel`,
  round: `Round`,
  organisation_title: `Organization`,
  select_donation_status: `Select Donation Status`,
  delete_file: `Delete File`,
  deleting_file_para: `Deleting this file will remove it permanently`,
  delete_donation: `Delete Donation`,
  delete_donation_para: `Deleting this donation will remove all related information permanently`,
  cancel_changes: `Cancel Changes`,
  remove_title: `Remove`,
  will_remove_changes_to_donation: `This will remove any changes made to the donation`,
  edit_title_uppercase: `EDIT`,
  current_report_period: `Current Report Period`,
  current_report_open: `Current Report Open`,
  current_report_close: `Current Report Close`,
  manage_title: `Manage`,
  open_date: `Open Date`,
  close_date: `Close Date`,
  period_title: `Period`,
  reporter_title: `Reporter`,
  edit_title: `Edit`,
  about_me: `About Me`,
  please_enter_valid_name: `Please enter a valid name`,
  please_enter_only_alphabets: `Please enter only alphabets`,
  name_Title: `Name`,
  phone_number_title: `Phone Number`,
  upload_purchase_order: `Upload Purchase Order`,
  programme_dates: `Programme Dates`,
  programme_start: `Programme Start`,
  programme_end: `Programme End`,
  currency: `Currency`,
  current_budget_year_end: `Current Budget Year End:`,
  approved_summary: `Approved Summary`,
  please_select_programme: `Please select a programme`,
  generate: `Generate`,
  opportunities_supported: `Opportunities Supported`,
  approved_opportunities: `Approved Opportunities`,
  planned_impact: `Planned Impact (Approved Opportunities)`,
  grants: `Grants`,
  planned_beneficiaries: `Planned Beneficiaries`,
  direct: `Direct`,
  indirect: `Indirect`,
  primary: `Primary`,
  secondary: `Secondary`,
  all: `All`,
  programme_name: `Programme Name`,
  reset: `Reset`,
  my_feedback: `My Feedback`,
  feedback_title: `Feedback`,
  select_opportunity: `Select Opportunity`,
  please_select_opportunity: `Please select an opportunity`,
  select_report: `Select Report`,
  please_select_report: `Please select a report`,
  editing_title: `Editing`,
  enabled_title: `Enabled`,
  locked_title: `Locked`,
  content_title_uppercase: `CONTENT`,
  feedback_title_uppercase: `FEEDBACK`,
  no_data: `No Data`,
  program_status: `Programme Status`,
  un_sdgs: `UN SDGs`,
  more_title: `More`,
  program_location: `Programme Location`,
  asset_name: `Asset Name`,
  asset_location: `Asset Location`,
  portfolio_title: `Portfolio`,
  review_title: `Review`,
  payment_date: `Payment Date`,
  secondary_UN_SDG: `Secondary UN SDGs`,
  summary_title: `Summary`,
  select_the_programme_you_wish_to_receive: `Select the programme you wish to receive a report for on the asset selected above.`,
  manager: `Manager`,
  add_managers_who_can_invite_reporter: `Add managers who can invite reporters on your behalf. Note: Internal users from your organisation can be added from your`,
  admin_page: `admin page`,
  inviting_existing_user: `Inviting Existing User`,
  invite_new: `Invite new`,
  external_users: `external users`,
  by_entering_their_email_address: `by entering their email address`,
  optional_add_reporters: `Optional. Add reporters who will complete programme reports for your assets.`,
  manage_report: `Manage Report`,
  add_asset: `Add Asset`,
  budget_history: `Budget History`,
  milestones: `Milestones`,
  programme_name_title: `Programme  Name`,
  application: `Application`,
  create: `Create`,
  comment_title: `Comment`,
  cash_donation_saved_successfully: `Cash donation saved.`,
  upload_invoice: `Upload Invoice`,
  upload_title: `Upload`,
  confirm_receipt: `Confirm Receipt`,
  received_title: `Received`,
  please_confirm_donation_received: `Please confirm that this donation has been received.`,
  success_donation_received: `Success - donation received`,
  success_invoice_uploaded: `Success - invoice uploaded`,
  click_to_upload: `Click to Upload`,
  settings: `Settings`,
  internal_only: `Internal Only`,
  visibility_title: `Visibility`,
  public_title: `Public`,
  private: `Private`,
  completed: `Completed`,
  content_package: `Content Package`,
  please_select_interaction_status: `Please select the interaction status.`,
  go_back: `Go Back`,
  budget_dates: `Budget Dates`,
  budget_recurring: `Budget Recurring?`,
  objectives: `Objectives`,
  supporting_materials: `Supporting Material`,
  web_links: `Web Links`,
  administrators: `Administrators`,
  application_criteria: `Application Criteria`,
  documentation_required: `Documentation Required`,
  available_support: `Available Support`,
  none: `None`,
  eligibility_checklist: `Eligibility Checklist`,
  location_supported: `Location Supported`,
  project_must_be_within: `Project must be within`,
  of_this_location: `of this location`,
  expand_all: `Expand All`,
  entities_supported: `Entities Supported`,
  mandatory: `Mandatory`,
  expected_budget: `Expected Budget`,
  per_opportunity: `Per Opportunity`,
  specific_skills_available: `Specific Skill Available`,
  general_skills_available: `General Skills Available`,
  any: `Any`,
  application_accepted_between: `Application Accepted between`,
  donation_to_be_made_between: `Donation to Be Made Between`,
  interim_update_requested: `Interim Update Requested`,
  final_report_requested: `Final Report Requested`,
  show_all_goals: `Show All Goals`,
  any_location: `Any Location`,
  apply: `Apply`,
  eligibility: `Eligibility`,
  before_applying_please_confirm_your_eligibility: `Before applying, please confirm your eligibility:`,
  please_confirm_that_your_project_is_located_within_or_that_it_benefits_those_within_the_supported_locations: `Please confirm that your project is located within or that it benefits those within, the supported locations.`,
  please_select: `Please select`,
  project_description: `Project Description`,
  please_share_a_summary_of_your_project: `Please share a summary of your project`,
  enter_project_summary: `Enter project summary`,
  please_describe_your_project: `Please describe your project`,
  for_example_what_do_you_want_to_do: `For example, what do you want to do, how you will do this, have you identified a need for the project and who will lead it?`,
  this_application_is_to: `This application is to:`,
  application_accepted: `Applications accepted:`,
  application_sections: `Applications sections`,
  application_preview: `Application preview`,
  application_guidance: `Application guidance`,
  in_the_application_form: `In the application form, you will be asked to share details of a project you are requesting funding for.`,
  next_title: `‘Next’`,
  save_n_exit_title: `‘Save & Exit’`,
  submit_title: `‘Submit’`,
  saves_your_data_and_moves: `saves your data and moves you to the next screen in the application form. Your answers will automatically be saved, unless you have an internet connectivity issue.`,
  saves_your_data_and_closes: `saves your data and closes the application form, for you to return to later. You will need to fill in all required fields to ‘Save & Exit’ (you can put in random values e.g. X as placeholder, to click ‘Next’)`,
  sends_your_application_form_to_the_company: `sends your application form to the company. This button appears on the final screen of the application form.`,
  to_access_a_saved_application: `To access a saved application: Click into Applications and Action Required.`,
  to_view_a_submitted_application: `To view a submitted application: Click into Applications and Pending Response`,
  start: `Start`,
  application_form: `Application Form`,
  your_application: `Your Application`,
  what_is_the_name_of_your_project: `What is the name of your project?`,
  please_complete_each_screen: `Please complete each screen of the application form. On the last screen, you will see a preview of your application and from here, you can click ‘Submit’ to send it to the company.`,
  project_name: `Project Name`,
  add_a_banner_image: `Add a Banner Image`,
  this_will_show_at_the_top_of_your_application_form: `This will show at the top of your application form.`,
  choose_an_image_from_bizGive: `Choose an image from BizGive`,
  upload_your_own_banner_image: `Upload your own banner image`,
  additional_images: `Additional Images`,
  bizGive_images: `BizGive Images`,
  where_is_your_project_located: `Where is your project located?`,
  search_for_and_select_the_address_of_your_location: `Search for and select the address of your location/s. Or Alternatively,`,
  click: `click`,
  any_place_or_point_on_the_map_to_select_it: `any place or point on the map to select it.`,
  add_or_Edit_the_name_for_your_location_and_press: `Add or Edit the name for your location and press ‘Add another’ to add more locations.`,
  add_additional_location: `Add additional location`,
  one_or_more_project_locations_are_located_outside_of_the_programme_area: `One or more project locations are located outside of the programme area.`,
  please_change_your_project_location_or_explain_how_your_project_will_benefit_the_programme_area_below: `Please change your project location OR explain how your project will benefit the programme area below`,
  how_will_it_benefit_the_programme_area: `How will it benefit the programme area?`,
  project_dates: `Project Dates`,
  when_is_your_project_happening: `When is your project happening?`,
  this_can_be_an_estimate: `This can be an estimate, as you will be asked to edit or confirm your dates if your application is successful.`,
  projects_must_start_within_12_months: `Projects must start within opportunityStartWithinMonths months.`,
  projects_must_not_be_longer_than_6_months: `Projects must not be longer than opportunityLengthMonths months.`,
  estimated_start: `Estimated Start`,
  estimated_finish: `Estimated Finish`,
  is_there_a_deadline_when_you_need_to_receive_a_donation_by: `Is there a deadline when you need to receive a donation by, for your project to be able to go ahead?`,
  if_not_please_leave_this_blank: `If not, please leave this blank.`,
  enter_date: `Enter Date`,
  what_support_are_you_applying_for: `What support are you applying for?`,
  in_kind: `In Kind`,
  how_much_are_you_applying_for: `How much are you applying for?`,
  enter_amount: `Enter Amount`,
  how_much_does_your_project_cost_in_total: `How much does your project cost in total?`,
  you_are_applying_for_percentage_of_the_total_cost_of_your_project: `You are applying for percentage of the total cost of your project`,
  how_will_you_use_any_support_awarded: `How will you use any support awarded?`,
  enter_number_of_items_or_activity: `Enter the number of items or activity`,
  enter_description: `Enter description`,
  where_will_additional_funds_come_from: `Where will additional funds come from?`,
  internal_resources: `Internal Resources`,
  external_funding: `External Funding`,
  have_you_already_secured_this_funding: `Have you already secured this funding?`,
  can_your_project_proceed_if_you: `Can your project proceed if you don’t secure this additional funding?`,
  what_outputs_will_the_support_help_you_to_achieve: `What outputs will the support help you to achieve?`,
  your_outputs_will_show_like_this_in_your_application_form: `Your outputs will show like this in your application form:`,
  what_items_or_services_do_you_need: `What items or services do you need?`,
  for: `for`,
  what_volunteering_support_do_you_need: `What volunteering support do you need?`,
  edit_image: `Edit Image`,
  change_image: `Change Image`,
  delete_image: `Delete image`,
  click_delete_to_remove_this_image: `Click ‘Delete’ to remove this image.`,
  option: `Option`,
  add: `Add`,
  tips: `Tips`,
  what_is_an_output: `What is an output?`,
  an_output_is_what_you_expect_to_achieve_from_the_support_awarded_below_are_some_examples: `An output is what you expect to achieve from the support awarded. Below are some examples:`,
  number_new_teaching_assistants_trained: `5 new teaching assistants trained`,
  number_new_teachers_trained: `10 new teachers trained`,
  number_workshops_hosted: `3 workshops hosted`,
  number_energy_audits_completed: `4 energy audits completed`,
  number_people_more_aware_of_climate_change_in_our_community: `200 people more aware of climate change in our community`,
  number_new_playground_installed: `1 new playground installed`,
  please_enter: `Please enter`,
  which_of_the_company_impact_objectives_will_your_project_support: `Which of the company’s impact objectives will your project support?`,
  what_outcomes_do_you_hope_your_project_will_achieve: `What outcomes do you hope your project will achieve?`,
  what_is_an_outcome: `What is an outcome?`,
  an_outcome_is_the_change_you_hope_to_see_as_a_result_of_your_project_below_are_some_examples: `An outcome is the change you hope to see as a result of your project. Below are some examples.`,
  objective_protect_and_improve_the_local_environment: `Objective: Protect and Improve the Local Environment`,
  outcome_improve_fish_populations_through_river_restoration: `Outcome: Improve fish populations through river restoration.`,
  objective_create_and_improve_local_amenities: `Objective: Create and Improve Local Amenities`,
  outcome_improve_the_energy_efficiency_of_the_village_hall_with_new_windows: `Outcome: Improve the energy efficiency of the village hall with new windows.`,
  objective_engage_the_local_community: `Objective: Engage the Local Community`,
  outcome_reduce_isolation_and_loneliness_in_older_people: `Outcome: Reduce isolation and loneliness in older people.`,
  how_will_you_achieve_these_outcomes: `How will you achieve these outcomes?`,
  briefly_describe_your_approach_to_achieving_the_outcomes: `Briefly describe your approach to achieving the outcomes.`,
  below_are_some_examples: `Below are some examples:`,
  we_will_remove_excess_silt: `We will remove excess silt, so that the breeding grounds of the fish are restored.`,
  we_will_replace_our_old_windows_with_triple_glazed_windows: `We will replace our old windows with triple-glazed windows.`,
  we_will_run_weekly_workshops_bringing_older_people_together: `We will run weekly workshops bringing older people together.`,
  how_will_you_achieve_this_outcome: `How will you achieve this outcome?`,
  please_use_no_more_than_25_words_as_you_will_be_asked_to_describe_your_approach_on_the_next_screen: `Please use no more than 25 words as you will be asked to describe your approach on the next screen.`,
  enter_outcome: `Enter outcome`,
  please_enter_numeric_value: `Please enter numeric value`,
  expected_decision_date: `Expected decision date`,
  beneficiaries: `Beneficiaries`,
  who_or_what_will_benefit_from_your_project: `Who or what will benefit from your project?`,
  who_are_the_social_beneficiaries: `Who are the social beneficiaries?`,
  select_beneficiary_type: `Select Beneficiary Type`,
  how_many_people_will_benefit: `How many people will benefit?`,
  direct_beneficiaries: `Direct Beneficiaries`,
  enter_estimated_number: `Enter Estimated Number`,
  indirect_beneficiaries: `Indirect Beneficiaries`,
  how_will_the_environment_benefit: `How will the environment benefit?`,
  for_example_species_of_new_plants_introduced_ppm_of_CO2_reduced_terrestrial_animals_protected: `For example, 10 species of new plants introduced, 10 ppm of CO2 reduced, 10 terrestrial animals protected.`,
  select_type_title: `Select Type`,
  select_sub_type: `Select Sub-type`,
  select_units: `Select Units`,
  your_environmental_beneficiaries_will_show_like_this_in_your_application_form: `Your environmental beneficiaries will show like this in your application form:`,
  environmental_beneficiaries: `Environmental Beneficiaries`,
  the_environmental_beneficiaries_drop_down_fields_enable_you_to_share_additional_details: `The environmental beneficiaries drop-down fields enable you to share additional details, such as whether you’re supporting species, habitats or buildings, plants or animals and what actions you’re taking.`,
  select_type_title_head: `Select Type:`,
  choose_whether_your_project_supports_habitats_such_as_forests_or_woodlands: `Choose whether your project supports habitats, such as forests or woodlands, species, such as plants or animals, or buildings, such as schools or shared community buildings, like a village hall.`,
  select_sub_type_head: `Select Sub-type:`,
  choose_the_type_of_habitat_species_or_building_you_are_supporting: `Choose the type of habitat, species or building you are supporting. For example, select freshwater if you’re supporting rivers or lakes.`,
  select_action_head: `Select Action:`,
  choose_the_type_of_action_you_re_taking_to_support_the_species: `Choose the type of action you’re taking to support the species, habitat or building. For example, select protected if you’re protecting endangered species, restored if you’re regenerating a habitat or greenhouse gas emissions reduction if you’re lowering greenhouse gases in a building.`,
  enter_amount_head: `Enter Amount:`,
  for_species_estimate_how_many_plants_or_animals_you_re_supporting_through_your_project: `For species, estimate how many plants or animals you’re supporting through your project.`,
  for_habitats_estimate_how_many_habitats_you_re_supporting_number_or_the_total_area_in_hectares: `For habitats, estimate how many habitats you’re supporting (number) or the total area (in hectares).`,
  for_buildings_estimate_how_many_buildings_you_re_supporting: `For buildings, estimate how many buildings you’re supporting (number) or by how much you’re reducing energy, water or greenhouse gases (Tonnes of CO2 equivalents (CO2e)).`,
  select_units_head: `Select Units:`,
  number_is_an_estimate_of_the_total_number_of_habitats_species_or_buildings_you_plan_to_support: `Number is an estimate of the total number of habitats, species or buildings you plan to support.`,
  hectares_is_an_estimate_of_the_total_area_of_a_habitat_youre_supporting: `Hectares is an estimate of the total area of a habitat you’re supporting.`,
  social_beneficiaries: `Social Beneficiaries`,
  select_the_type_of_beneficiaries_your_project_supports: `Select the type of beneficiaries your project supports. Then estimate the total number of beneficiaries you plan to support across all beneficiary types.`,
  direct_beneficiaries_head: `Direct beneficiaries:`,
  the_number_of_people_who_directly_benefit_from_your_opportunity: `The number of people who directly benefit from your opportunity.`,
  indirect_beneficiaries_head: `Indirect beneficiaries:`,
  the_number_of_people_whose_benefits_can_be_attributed_to_a_direct_beneficiary: `The number of people whose benefits can be attributed to a direct beneficiary.`,
  for_example_if_your_project_supports_children_with_learning_disabilitie: `For example, if your project supports 10 children with learning disabilities, the direct beneficiaries are the 10 children. The indirect beneficiaries are the families of those children.`,
  habitats: `Habitats`,
  forest: `Forest`,
  deserts: `Desert`,
  mountains: `Mountain`,
  coastal: `Coastal`,
  marine: `Marine`,
  freshwater: `Freshwater`,
  polar: `Polar`,
  wetlands: `Wetlands`,
  rainforest: `Rainforest`,
  arctic: `Arctic`,
  tundra: `Tundra`,
  restored: `Restored`,
  protected: `Protected`,
  created: `Created`,
  hectares: `Hectares`,
  established: `Established`,
  benefitted: `Benefitted`,
  terrestrial_animals: `Terrestrial Animals`,
  aquatic_animals: `Aquatic Animals`,
  terrestrial_plants: `Terrestrial Plants`,
  aquatic_plants: `Aquatic Plants`,
  species: `Species`,
  select_category: `Select category`,
  select_sub_category: `Select Sub-Category`,
  please_add_at_least_one_outcome_under_selected_objectives: `Please add at least 1 outcome under selected objectives`,
  please_enter_your_approach_for_each_outcome: `Please enter your approach for each outcome`,
  create_n_manage_sharing: `Create & Manage Sharing`,
  access_closing_date: `Access Closing Date`,
  please_choose_closing_date: `Please choose Closing Date`,
  newly_invited_users_will_receive_an_email_invitation: `Newly invited users will receive an email invitation.`,
  existing_invited_users_will_not_be_notified_of_changes: ` Existing invited users will not be notified of changes.`,
  update: `Update`,
  user_already_exists: `User already exists`,
  verify_your_email: `Verify Your Email`,
  please_verify_your_email_to_receive_a_magic_link: `Please verify your email to receive a magic link to access your Feedback Portal.`,
  emissions: 'Emissions',
  greenhouse_gases: 'Greenhouse Gases',
  reduced: 'Reduced',
  captured: 'Captured',
  prevented: 'Prevented',
  tonnes_of_CO2_equivalents_C02e: 'Tonnes of CO2 equivalents (C02e)',
  additional_questions: `Additional Questions`,
  required_documents: `Required Documents`,
  document_name: `Document Name`,
  custom_question: `Custom question?`,
  enter_answer: `Enter Answer`,
  if_you_are_awarded_support_you_may_be_required_to_share_interim_Updates: `If you are awarded support, you may be required to share Interim Updates and a one-off Final Report with the company. You will receive an email notification from BizGive if and when these are due.`,
  can_you_meet_the_required_submission_deadlines: `Can you meet the required submission deadlines?`,
  based_on_the_estimated_start_and_finish_date_of_your_project: `Based on the estimated start and finish date of your project, please confirm you could meet the submission deadlines below. You will be able to update your project dates if your application is approved.`,
  interim_updates: `Interim Updates`,
  final_report: `Final Report`,
  this_value_cannot_be_lower_than_the_amount_you_are_applying_for: `This value cannot be lower than the amount you are applying for`,
  what_is_an_interim_update: `What is an Interim Update?`,
  an_update_required_by_the_company_on_the_progress_of_your_project: `An update required by the company on the progress of your project.`,
  download_interim_update_report_template: `Download Interim Update Report Template`,
  what_is_a_final_report: `What is a Final Report`,
  a_single_report_required_by_the_company_on_what_your_project_achieved_with_the_support_provided: `A single report required by the company on what your project achieved with the support provided.`,
  download_final_report_template: `Download Final Report Template`,
  how_will_i_know_when_an_interim_update_or_final_report_is_due: `How will I know when an Interim Update or Final Report is due?`,
  you_will_receive_an_email_notification_from_bizGive_when_the_deadline_is_approaching: `You will receive an email notification from BizGive when the deadline is approaching. Follow the instructions in these emails to complete and submit these reports to the company.`,
  contact_details: `Contact Details`,
  after_submitting_your_application_you_will_receive_email_notifications_from_BizGive_on_its_status: `After submitting your application, you will receive email notifications from BizGive on its status.`,
  please_add_another_contact_in_case_of_your_absence: `Please add another contact in case of your absence.`,
  if_theyre_already_on_BizGive_select_their_name_below: `If they’re already on BizGive, select their name below:`,
  invite_a_colleague_to_join_by_entering_their_email_address_below: `Invite a colleague to join by entering their email address below:`,
  select_user: `Select User`,
  what_is_an_indicator: `What is an indicator?`,
  an_indicator_is_a_measure_of_progress: `An indicator is a measure of progress. It includes a target and milestones for reaching this target. For example, the number of people you plan to help and how this will increase over time.`,
  an_indicator_is_an_estimate: `An indicator is an estimate, so do not worry if your plans change. If your application is approved, you will be asked to update your indicators.`,
  indicator_examples: `Indicator Examples`,
  no_of_people_gaining_a_new_skill_or_qualification: `No. of people gaining a new skill or qualification`,
  no_of_workshops_hosted: `No. of workshops hosted`,
  decrease_in_local_unemployment: `% decrease in local unemployment`,
  when_do_i_update_my_indicators: `When do I update my indicators?`,
  if_your_application_is_approved: `If your application is approved, you will receive email notifications from BizGive when you are required to update your indicators.`,
  how_often_do_I_update_my_indicators: `How often do I update my indicators?`,
  you_will_be_asked_to_update_your_indicators_at_the_same_time: `You will be asked to update your indicators at the same time as being asked to submit your Interim Update or Final Report.`,
  measuring_progress: `Measuring Progress`,
  the_company_has_requested_you_to_add_indicators_to_measure_the_progress_of_your_project: `The company has requested you to add indicators to measure the progress of your project.`,
  general_indicators: `General Indicators`,
  these_indicators_are_general_to_your_opportunity_or_entity_please_click_add_to_create_them: `These indicators are general to your opportunity or entity. Please click ‘Add’ to create them.`,
  indicator: `Indicator`,
  required: `Required?`,
  target: `Target`,
  objective_outcome_specific_indicators: `Objective/Outcome Specific  Indicators`,
  the_indicators_below_are_linked_to_your_selected_objectives_and_outcomes_please_click_add_to_create_them: `The indicators below are linked to your selected objectives and outcomes. Please click ‘Add’ to create them.`,
  objective: `Objective`,
  outcome: `Outcome`,
  mandatory_title: `Mandatory`,
  about_your_project: `About Your Project`,
  your_request: `Your Request`,
  planned_impact_title: `Planned Impact`,
  award_criteria: `Award Criteria`,
  step_1: `Step 1`,
  step_2: `Step 2`,
  step_3: `Step 3`,
  step_4: `Step 4`,
  step_5: `Step 5`,
  submit_your_application: `Submit Your Application`,
  to_submit_your_application_you_must_read_and_agree_to_the_application: `To submit your application you must read and agree to the application`,
  terms_and_conditions: `Terms and Conditions`,
  below: `below.`,
  please_note_you_will_not_be_able_to_edit_your_application_after_it_has_been_submitted_unless_requested_to_by_the_company_youre_applying_to: `Please note, you will not be able to edit your application after it has been submitted, unless requested to by the company you’re applying to.`,
  by_submitting_this_application_you_will_be_sharing_your_application_user_details_and_ongoing_entity_profile_information_with_the_funder: `By submitting this application you will be sharing your application, user details and ongoing entity profile information with the funder.`,
  enter_caption: `Enter caption...`,
  impact_alignment: `Impact Alignment`,
  approve: `Approve`,
  decline: `Decline`,
  requested_support: `Requested Support`,
  total_request: `Total Request`,
  total_project_budget: `Total Project budget`,
  funder_contribution: `Funder Contribution`,
  planned_expenditure: `Planned Expenditure`,
  additional_funding: `Additional Funding`,
  additional_funding_is_required_to_meet_the_total_opportunity_budget: `Additional funding is required to meet the total opportunity budget`,
  where_will_additional_funds_be_sourced: `Where will additional funds be sourced?`,
  has_this_funding_been_secured: `Has this funding been secured?`,
  can_this_project_go_ahead_without_additional_funding: `Can this project go ahead without additional funding?`,
  outputs: `Outputs`,
  you_must_select_yes_to_proceed: `You must select yes to proceed`,
  please_enter_the_dates_within_the_above_mentioned_limits: `Please enter the dates within the above mentioned limits`,
  at_least_one_checkbox_must_be_selected: `At least one checkbox must be selected`,
  delete_confirmation: `Delete Confirmation`,
  unchecking_this_box_will_delete_any_underlying_data_you_have_entered_for_this_objective_or_outcome: `Unchecking this box will delete any underlying data you have entered for this objective or outcome.`,
  at_least_one_outcome_must_be_selected: `At least 1 outcome must be selected`,
  please_select_at_least_one_objective: `Please select at least 1 objective`,
  project_timelines: `Project Timelines`,
  project_location: `Project Location`,
  pending_title: `Pending`,
  view_indicator: `View Indicator`,
  indicators_status_confirmation: `Indicators status Confirmation`,
  indicators_successfully_submitted: `Indicators successfully submitted`,
  please_create_at_least_indicator_outcome: `Please create at least one indicator per outcome`,
  please_complete_n_delete_pending_indicators: `Please complete or delete pending indicators`,
  modal_will_be_closed_after_two_seconds: `The modal will be closed after two seconds`,
  please_enter_indicatorInterimActual: `Please enter indicatorInterimActual`,
  no_comments: `No comments`,
  enter_final_total: `Enter Final Total`,
  milestone_date: `Milestone Date`,
  accumulative_target: `Accumulative Target`,
  actuals: `Actuals`,
  evidence: `Evidence`,
  final_title: `Final`,
  total_target: `Total Target`,
  please_enter_total_accumulative_target: `Please enter total accumulative target`,
  please_add_value_for_historic_milestone: `Please add a value for each historic milestone`,
  cancel_uppercase: `CANCEL`,
  submit_uppercase: `SUBMIT`,
  press_submit_to_confirm: `Press Submit to confirm your entry. Note that this will be shared with`,
  confirmation: `Confirmation`,
  your_donor_n_can_not_be_edited: `your donor and can not be edited`,
  indicator_name: `Indicator Name`,
  please_enter_indicator_name: `Please enter indicator name`,
  main_focus: `Main Focus`,
  social_n_beneficiaries: `Social / Beneficiaries`,
  please_select_beneficiaries: `Please select the beneficiaries`,
  please_enter_unit: `Please enter unit`,
  how_will_you_measure_progress: `How will you measure progress?`,
  starting_number_level_indicator: `Starting number/level of your indicator`,
  if_you_do_not_know_this_then_enter_zero: `If you do not know this then enter 0`,
  target_number_or_level_reach: `Target number or level to reach`,
  target_we_aim_to_reach_is: `The target we aim to reach is`,
  from_starting_number_n_level: `from the starting number/level`,
  estimate_what_change_starting_para: `Optional: Estimate what the change in the starting number/level would be by the end of your Opportunity timeline if you did not intervene?`,
  your_total_effort_will_be: `Your total effort will be`,
  taking_account_you_did_not_intervene: `taking into account what would happen if you did not intervene`,
  how_will_you_gather_evidence: `How will you gather evidence?`,
  please_enter_details: `Please enter details`,
  evidence_verification_method: `Evidence verification method`,
  your_contribution: `Your Contribution`,
  measuring_your_impact: `Measuring Your Impact`,
  to_measure_the_progress_of_your_opportunity: `To measure the progress of your opportunity, the company has requested you to add indicators. Indicators allow you to set estimated targets, with milestones for achieving them.`,
  opportunity_indicators: `Opportunity Indicators`,
  these_indicators_are_general_to_your_opportunity_or_entity: `These indicators are general to your opportunity or entity. Please click 'Add' to create them.`,
  these_indicators_support_the_specific_objectives_and_outcomes: `These indicators support the specific Objectives and Outcomes of your opportunity. Please click 'Add' to create them`,
  describe_the_starting_situation: `Describe the starting situation:`,
  describe_the_final_situation: `Describe the desired target final situation:`,
  describe_the_final_title_situation: `Describe the desired final situation:`,
  please_confirm_that_you_can_provide_any_of_following_evidence_items: `Please confirm that you can provide the following evidence items`,
  starting_number_level_of_your_indicator: `Starting number of your indicator`,
  target_number_or_level_to_reach: `Target number to reach`,
  estimate_what_the_change_in_the_starting_number: `Optional: Estimate what the change in the starting number/level would be by the end of your Opportunity timeline if you did not intervene?`,
  the_target_we_aim_to_reach_is_employed_from_the_starting_number_level: `The target we aim to reach is employedNumber employed from the starting number/level`,
  your_total_impact_will_be_employed: `Your total impact will be impactNumber employed`,
  your_final_absolute_change_is_impactNumber: `Your final absolute change is impactNumber`,
  your_final_relative_change_is_impactNumber: `Your final relative change is impactNumber`,
  we_will_aim_for_sample_size_of_individuals: `We will aim for a sample size of 20 individuals...`,
  restrict_applications_to_within_certain_radius_of_this_location: `Restrict applications to within a certain radius of this location?`,
  enter_number: `Enter number`,
  biannually: `Biannually`,
  please_select_your_impact_strategy: `Please select your Impact Strategy`,
  reporting_requirements: `Reporting Requirements`,
  starting: `Starting`,
  create_indicator: `Create Indicator`,
  optional_title: `Optional`,
  estimate_what_the_level_would_be: `Estimate what the change in the starting number would be by your Project End Date if you did not intervene? (please add +/- sign)`,
  this_value_cannot_be_more_than_the_amount_you_are_applying_for: `This value cannot be more than the amount you are applying for`,
  you_must_apply_for_100_percentage_of_the_total_project_cost: `You must apply for 100% of the total project cost`,
  please_save_a_location: `Please save a location`,
  i_agree: `I agree`,
  project_running_dates: `Projects running dates`,
  donations_required_before: `Donations required before`,
  add_programme: `Add Programme`,
  budget_end_date: `Budget End Date`,
  application_window: `Application Window`,
  location: `Location`,
  choose_either_option: `Choose either option:`,
  copy_an_existing_programme: `Copy an existing programme`,
  create_a_new_programme: `Create a new programme`,
  what_is_the_name_of_your_programme: `What is the name of your programme?`,
  what_is_the_area_of_impact: `What is the area of impact?`,
  projects_or_impact_must_be_within: `Projects or impact must be within:`,
  a_custom_area: `A custom area`,
  an_administrative_boundary_eg_a_council_area_or_country: `An administrative boundary e.g. a council area or country.`,
  a_certain_distance_from_a_point: `A certain distance from a point.`,
  click_on_the_map_to_start_drawing_your_area: `Click on the map to start drawing your area, double click to complete it. If required, you can delete it from the area name option below the map by clicking the delete icon next to the right of the name.`,
  use_the_search_box_to_find_administrative_boundaries: `Use the search box to find administrative boundaries. View a boundary by clicking into the name, select a boundary by clicking ‘Choose’. Delete areas or edit names from area name section below the map. You can add multiple boundaries.`,
  click_on_the_map_to_add_a_point: `Click on the map to add a point, then enter a permitted radius below and press ‘Generate Area’ to show the area on the map. Start again by clicking on the map to add a new point.`,
  what_is_the_area_called: `What is the area called?`,
  programme_details: `Programme Details`,
  write_a_short_summary_of_your_programme: `Write a short summary of your programme`,
  write_a_longer_description: `Write a longer description`,
  do_you_wish_to_share_any_supporting_material: `Do you wish to share any supporting material?`,
  select_all_options: `Select all options`,
  indicators: `Indicators`,
  project_indicators: `Project indicators`,
  details: `Details`,
  programme_support: `Programme Support`,
  what_support_can_you_offer: `What support can you offer?`,
  what_is_your_budget: `What is your budget`,
  make_this_budget_amount_visible_in_your_programme_as_an_estimated_budget: `Make this budget amount visible in your programme as an ‘Estimated Budget’`,
  is_there_a_limit_donation_amount_per_project: `Is there a limit donation amount per project?`,
  projects_must_be_fully_funded_by_you_without_additional_third_party_funding: `Projects must be fully funded by you without additional third party funding`,
  does_this_budget_recur: `Does this budget recur?`,
  per_project: `Per Project`,
  no_it_is_fixed: `No, it is fixed.`,
  yes_annual_starting_on: `Yes, annual starting on:`,
  please_include_in_your_invoice_the_following_details: `Please include in your invoice the following details...`,
  share_any_invoice_instructions_for_approved_grantees_to_view: `Share any invoice instructions for approved grantees to view`,
  do_you_want_to_upload_an_invoice_template_for_approved_applicants_to_use: `Do you want to upload an invoice template for approved applicants to use?`,
  what_type_of_volunteering_can_you_offer: `What type of volunteering can you offer?`,
  what_skilled_volunteering_is_available: `What skilled volunteering is available?`,
  start_date: `Start Date`,
  end_date: `End Date`,
  general: `General`,
  skilled: `Skilled`,
  skill: `Skill`,
  number_of_item_service: `Number (of item/service)`,
  item_service_description: `Item/Service Description`,
  what_type_of_in_kind_support_can_you_offer: `What type of in kind support can you offer?`,
  timelines: `Timelines`,
  when_are_you_accepting_applications: `When are you accepting applications?`,
  a_date_range: `A date range`,
  ongoing: `Ongoing`,
  when_are_application_decisions_made: `When are application decisions made?`,
  periodically: `Periodically`,
  when_do_you_expect_to_make_donations: `When do you expect to make Donations?`,
  do_you_want_to_restrict_project_timelines: `Do you want to restrict Project Timelines?`,
  projects_must_start_within: `Projects must start within`,
  projects_must_not_be_longer_than: `Projects must not be longer than`,
  months: `months`,
  eligible_entities: `Eligible Entities`,
  what_type_of_entity_is_able_to_apply_to_your_programme: `What type of entity is able to apply to your programme?`,
  please_select_all_that_apply_below: `Please select all that apply below.`,
  uk: `UK`,
  international: `International`,
  applicants_must_agree_to_these_before_applying: `Applicants must agree to these before applying`,
  entity_project_must_be: `Entity/Project must be...`,
  application_questions: `Application Questions`,
  do_you_want_to_add_any_custom_application_questions: `Do you want to add any custom application questions?`,
  word_limit: `Word Limit`,
  question: `Question`,
  please_enter_your_application_terms_and_conditions: `Please enter your application terms and conditions`,
  we_ask_all_applicants_to_approve_this_before_submitting_their_application: `We ask all applicants to approve this before submitting their application.`,
  please_enter_a_link_to_your_privacy_policy: `Please enter a link to your privacy policy`,
  we_ask_all_applicants_to_acknowledge_this_before_submitting_their_application: `We ask all applicants to acknowledge this before submitting their application.`,
  measuring_impact: `Measuring Impact`,
  when_do_you_want_to_receive_interim_updates: `When do you want to receive ‘Interim Updates’?`,
  recurring_to_project_dates: `Recurring to project dates`,
  not_required: `Not required`,
  every: `Every`,
  days_between_project_start_and_end_dates: `Days between project start and end dates`,
  when_do_you_want_to_receive_a_final_report: `When do you want to receive a ‘Final Report’?`,
  relative_to_project_dates: `Relative to project dates`,
  days_after_the_project_end_date: `Days after the project end date`,
  are_there_any_additional_questions_you_would_like_to_ask_in_the_final_report: `Are there any additional questions you would like to ask in the Final Report?`,
  do_you_want_additional_legal_terms_for_entities_to_accept_on_submission_of_reports_to_you: `Do you want additional legal terms for entities to accept on submission of reports to you?`,
  programme_administration: `Programme Administration`,
  internal_approval_steps: `Internal Approval Steps`,
  approval_step: `Approval Step`,
  voting: `Voting`,
  scoring_category: `Scoring Category`,
  create_the_categories_that_you_would_like_to_evaluate_applications_against: `Create the categories that you would like to evaluate applications against E.g. ‘Value for money’ or ‘Total impact’`,
  insert: `Insert`,
  scoring_category_options: `Scoring Category Options`,
  detail_the_category_options_available_to_be_selected_for_the_above_categories: `Detail the category options available to be selected for the above categories with ‘numerical value’ of each option, so that a final score can be calculated. You can also optionally include a description. E.g value=1 and description = Low,  value=2 and description= Medium,  value =3 and description = High.`,
  list_the_scoring_category_options_with_a_value_and_an_optional_description: `List the scoring category options with a value and an optional description. For example, the value could be “1” and description “Low” or value “3” and description “High”.`,
  numerical_value: `Numerical Value`,
  optional_description: `Optional Description`,
  who_will_manage_this_programme: `Who will manage this programme?`,
  managers_can_edit_the_programme_interact_with_applicants_approve_applications_and_receive_related_email_notifications: `Managers can edit the programme, interact with applicants, approve applications and receive related email notifications.`,
  who_can_view_this_programme: `Who can view this programme`,
  viewers_can_view_the_programme_and_applications_but_not_edit_the_programme: `Viewers can view the programme and applications but not edit the programme, interact with applicants, approve applications nor receive related email notifications.`,
  programme_visibility: `Programme Visibility`,
  bizGive_open: `BizGive (Open)`,
  any_charity_signed_up_to_bizGive_can_view_and_apply_to_your_programme: `Any charity signed up to BizGive can view and apply to your programme.`,
  bizGive_invite_only: `BizGive (Invite Only)`,
  only_charities_you_invite_to_apply_to_your_programme_can_view_it: `Only charities you invite to apply to your programme can view it.`,
  public_open: `Public (Open)`,
  your_programme_will_have_its_own_link_and_branded_landing_page_on_bizGive: `Your programme will have its own link and branded landing page on BizGive, so you can share it anywhere outside the platform. Any charity signed-up to BizGive will also be able to view and apply.`,
  public_invite_only: `Public (Invite only)`,
  your_programme_will_have_its_own_link_and_branded_landing_page_on_bizGive_invite_them_to_apply: `Your programme will have its own link and branded landing page on BizGive, so you can share it anywhere outside the platform. It will not be visible to charities signed-up to BizGive, unless you invite them to apply.`,
  add_a_custom_programme_url: `Add a custom programme URL`,
  publish_your_programme: `Publish Your Programme`,
  this_will_make_your_programme_visible_as_per_your_visibility_settings: `This will make your programme visible as per your visibility settings.`,
  you_can_still_edit_all_details_of_the_programmes_after_publishing: `You can still edit all details of the programmes after publishing.`,
  required_document_name: `Required Document Name`,
  change: `Change`,
  number_of_days: `Number of days`,
  programme_approval: `Programme Approval`,
  there_must_be_at_least_1_votingScoreCriteria_added: `There must be at least 1 votingScoreCriteria added`,
  there_must_be_at_least_1_votingCriteriaOptions_added: `There must be at least 1 votingCriteriaOptions added`,
  publish: `Publish`,
  confirm: `Confirm`,
  project_timeline: `Project Timeline`,
  company: `Company`,
  this_programme_is_closing_in_number_days: `This programme is closing in number days`,
  this_programme_is_now_closed: `This programme is now closed`,
  the_application_window_is: `The application window is`,
  login_or_signup_into_bizGive_to_discover_new_funding_programmes: `Login or Signup into BizGive to discover new funding programmes.`,
  discover_more_programmes: `Discover more programmes`,
  end: `End`,
  to_apply_to_the: `To apply to the`,
  log_in_or_sign_up_to_create_your_free_bizGive_account: `Log In or Sign Up, to create your free BizGive account.`,
  if_youre_new_to_BizGive_create_an_organisation_profile: `If you’re new to BizGive, create an organisation profile.`,
  complete_your_application_to_the_programme: `Complete your application to the programme.`,
  receive_alerts_when_action_is_required_or_a_funding_decision_is_made: `Receive alerts when action is required or a funding decision is made.`,
  apply_to_other_programmes_from_the_discover_section: `Apply to other programmes from the Discover section.`,
  entity_location: `Entity Location`,
  which_country_must_entities_be_located_in_leave_blank_if_any_are_accepted: `Which country must entities be located in? Leave blank if any are accepted`,
  search_country: `Search Country`,
  afghanistan: `Afghanistan`,
  albania: `Albania`,
  algeria: `Algeria`,
  andorra: `Andorra`,
  angola: `Angola`,
  antigua_and_barbuda: `Antigua and Barbuda`,
  argentina: `Argentina`,
  armenia: `Armenia`,
  australia: `Australia`,
  austria: `Austria`,
  azerbaijan: `Azerbaijan`,
  bahrain: `Bahrain`,
  bangladesh: `Bangladesh`,
  barbados: `Barbados`,
  belarus: `Belarus`,
  belgium: `Belgium`,
  belize: `Belize`,
  benin: `Benin`,
  bhutan: `Bhutan`,
  bolivia: `Bolivia`,
  bosnia_and_herzegovina: `Bosnia and Herzegovina`,
  botswana: `Botswana`,
  brazil: `Brazil`,
  brunei: `Brunei`,
  bulgaria: `Bulgaria`,
  burkina_faso: `Burkina Faso`,
  burundi: `Burundi`,
  cambodia: `Cambodia`,
  cameroon: `Cameroon`,
  canada: `Canada`,
  cape_verde: `Cape Verde`,
  central_african_republic: `Central African Republic`,
  chad: `Chad`,
  chile: `Chile`,
  china: `China`,
  colombia: `Colombia`,
  comoros: `Comoros`,
  congo: `Congo`,
  congo_democratic_republic: `Congo (Democratic Republic)`,
  costa_rica: `Costa Rica`,
  croatia: `Croatia`,
  cuba: `Cuba`,
  cyprus: `Cyprus`,
  czechia: `Czechia`,
  denmark: `Denmark`,
  djibouti: `Djibouti`,
  dominica: `Dominica`,
  dominican_republic: `Dominican Republic`,
  east_timor: `East Timor`,
  ecuador: `Ecuador`,
  egypt: `Egypt`,
  el_salvador: `El Salvador`,
  equatorial_guinea: `Equatorial Guinea`,
  eritrea: `Eritrea`,
  estonia: `Estonia`,
  eswatini: `Eswatini`,
  ethiopia: `Ethiopia`,
  fiji: `Fiji`,
  finland: `Finland`,
  france: `France`,
  gabon: `Gabon`,
  georgia: `Georgia`,
  germany: `Germany`,
  ghana: `Ghana`,
  greece: `Greece`,
  grenada: `Grenada`,
  guatemala: `Guatemala`,
  guinea: `Guinea`,
  guinea_bissau: `Guinea-Bissau`,
  guyana: `Guyana`,
  haiti: `Haiti`,
  honduras: `Honduras`,
  hungary: `Hungary`,
  iceland: `Iceland`,
  india: `India`,
  indonesia: `Indonesia`,
  iran: `Iran`,
  iraq: `Iraq`,
  ireland: `Ireland`,
  israel: `Israel`,
  italy: `Italy`,
  ivory_coast: `Ivory Coast`,
  jamaica: `Jamaica`,
  japan: `Japan`,
  jordan: `Jordan`,
  kazakhstan: `Kazakhstan`,
  kenya: `Kenya`,
  kiribati: `Kiribati`,
  kosovo: `Kosovo`,
  kuwait: `Kuwait`,
  kyrgyzstan: `Kyrgyzstan`,
  laos: `Laos`,
  latvia: `Latvia`,
  lebanon: `Lebanon`,
  lesotho: `Lesotho`,
  liberia: `Liberia`,
  libya: `Libya`,
  liechtenstein: `Liechtenstein`,
  lithuania: `Lithuania`,
  luxembourg: `Luxembourg`,
  madagascar: `Madagascar`,
  malawi: `Malawi`,
  malaysia: `Malaysia`,
  maldives: `Maldives`,
  mali: `Mali`,
  malta: `Malta`,
  marshall_islands: `Marshall Islands`,
  mauritania: `Mauritania`,
  mauritius: `Mauritius`,
  mexico: `Mexico`,
  federated_states_of_micronesia: `Federated States of Micronesia`,
  moldova: `Moldova`,
  monaco: `Monaco`,
  mongolia: `Mongolia`,
  montenegro: `Montenegro`,
  morocco: `Morocco`,
  mozambique: `Mozambique`,
  myanmar_burma: `Myanmar (Burma)`,
  namibia: `Namibia`,
  nauru: `Nauru`,
  nepal: `Nepal`,
  netherlands: `Netherlands`,
  new_zealand: `New Zealand`,
  nicaragua: `Nicaragua`,
  niger: `Niger`,
  nigeria: `Nigeria`,
  north_korea: `North Korea`,
  north_macedonia: `North Macedonia`,
  norway: `Norway`,
  oman: `Oman`,
  pakistan: `Pakistan`,
  palau: `Palau`,
  panama: `Panama`,
  papua_new_guinea: `Papua New Guinea`,
  paraguay: `Paraguay`,
  peru: `Peru`,
  philippines: `Philippines`,
  poland: `Poland`,
  portugal: `Portugal`,
  qatar: `Qatar`,
  romania: `Romania`,
  russia: `Russia`,
  rwanda: `Rwanda`,
  st_kitts_and_nevis: `St Kitts and Nevis`,
  st_lucia: `St Lucia`,
  st_vincent: `St Vincent`,
  samoa: `Samoa`,
  san_marino: `San Marino`,
  sao_tome_and_principe: `Sao Tome and Principe`,
  saudi_arabia: `Saudi Arabia`,
  senegal: `Senegal`,
  serbia: `Serbia`,
  seychelles: `Seychelles`,
  sierra_leone: `Sierra Leone`,
  singapore: `Singapore`,
  slovakia: `Slovakia`,
  slovenia: `Slovenia`,
  solomon_islands: `Solomon Islands`,
  somalia: `Somalia`,
  south_africa: `South Africa`,
  south_korea: `South Korea`,
  south_sudan: `South Sudan`,
  spain: `Spain`,
  sri_lanka: `Sri Lanka`,
  sudan: `Sudan`,
  suriname: `Suriname`,
  sweden: `Sweden`,
  switzerland: `Switzerland`,
  syria: `Syria`,
  tajikistan: `Tajikistan`,
  tanzania: `Tanzania`,
  thailand: `Thailand`,
  the_bahamas: `The Bahamas`,
  the_gambia: `The Gambia`,
  togo: `Togo`,
  tonga: `Tonga`,
  trinidad_and_tobago: `Trinidad and Tobago`,
  tunisia: `Tunisia`,
  turkey: `Turkey`,
  turkmenistan: `Turkmenistan`,
  tuvalu: `Tuvalu`,
  uganda: `Uganda`,
  ukraine: `Ukraine`,
  united_arab_emirates: `United Arab Emirates`,
  united_kingdom: `United Kingdom`,
  united_states: `United States`,
  uruguay: `Uruguay`,
  uzbekistan: `Uzbekistan`,
  vanuatu: `Vanuatu`,
  vatican_city: `Vatican City`,
  venezuela: `Venezuela`,
  vietnam: `Vietnam`,
  yemen: `Yemen`,
  zambia: `Zambia`,
  zimbabwe: `Zimbabwe`,
  already_registered: `Already registered?`,
  login_here: `Login here`,
  application_to: `Application to`,
  continue_application :`Continue Application`,
  delete_application: `Delete Application`,
  edit_resubmit: `Edit & Resubmit`,
  withdraw: `Withdraw`,
  this_will_withdraw_your_application_and_return_it_to_a_pending_state: `This will withdraw your application and return it to a ‘Pending’ state.`,
  this_will_delete_your_application_and_saved_data: `This will delete your application and saved data.`,
  withdraw_application: `Withdraw Application`,
  send: `Send`,
  enable_editing_of_application: `Enable Editing of Application`,
  application_editing_enabled: `Application Editing enabled`,
  pre_approve: `Pre-Approve`,
  withdraw_pre_approval: `Withdraw PreApproval`,
  confirm_approval: `Confirm Approval`,
  grant_award: `Grant Award`,
  please_confirm_you_wish_to_approve_this_application: `Please confirm you wish to approve this application.`,
  share_a_message: `Share a message:`,
  confirm_decline: `Confirm Decline`,
  please_explain_your_decision_to_the_applicant: `Please explain your decision to the applicant`,
  application_related: `Application Related`,
  entity_related: `Entity Related`,
  programme_related: `Programme Related`,
  add_a_message: `Add a Message`,
  there_are_items_we_cannot_fund: `There are items we cannot fund`,
  the_impact_of_your_project_is_not_clear_enough: `The impact of your project is not clear enough`,
  the_impact_of_your_project_is_lower_relative_to_other_applications: `The impact of your project is lower relative to other applications.`,
  your_entity_does_not_meet_our_criteria: `Your entity does not meet our criteria`,
  your_project_does_not_align_closely_enough_with_our_objectives: `Your project does not align closely enough with our objectives`,
  other_projects_more_closely_aligned_with_our_objectives: `Other projects more closely aligned with our objectives`,
  your_project_did_not_fully_meet_our_eligibility_criteria: `Your project did not fully meet our eligibility criteria`,
  funding_in_this_round_has_already_been_disbursed_to_similar_projects: `Funding in this round has already been disbursed to similar projects`,
  please_select_an_decline_option_or_write_a_message: `Please select an decline option or write a message`,
  confirm_pre_approval: `Confirm Pre-Approval`,
  please_confirm_your_pre_approval: `Please confirm your pre-approval.`,
  please_confirm_your_approval: `Please confirm your approval.`,
  pre_approving_will_not_notify_the_applicant: `Pre-approving will not notify the applicant.`,
  approving_will_not_notify_the_applicant: `Approving will not notify the applicant.`,
  internal_review: `Internal Review`,
  entity_history: `Entity History`,
  you_have_received_an_application_from_this_entity_before: `You have received an application from this entity before.`,
  approval: `Approval`,
  approved_by: `Approved By`,
  confirm_step_completion: `Confirm Step Completion`,
  please_confirm_this_step_has_been_completed: `Please confirm this step has been completed.`,
  total_score: `Total Score`,
  voters: `Voters`,
  category_totals: `Category Totals`,
  name: `Name`,
  telephone: `Telephone`,
  email: `Email`,
  category_score: `Category Score`,
  date: `Date`,
  my_vote: `My Vote`,
  saved: `Saved`,
  vote: `Vote`,
  value_for_money: `Value for Money`,
  total_impact: `Total Impact`,
  total: `Total`,
  comments: `Comments`,
  edit: `Edit`,
  what_are_your_overall_comments: `What are your overall comments?`,
  select_score : `Select Score`,
  please_select_at_least_one_checkbox: `Please select at least one checkbox`,
  create_your_profile: `Create Your Profile`,
  on_the_following_screens_you_will_be_asked_to_complete_a_profile_of_your_entity: `On the following screens you will be asked to complete a profile of your entity.`,
  how_your_profile_is_used: `How Your Profile is Used`,
  once_your_profile_is_complete_it_will_be_automatically_attached_to_any_application_you_submit_through_bizGive: `Once your profile is complete, it will be automatically attached to any application you submit through BizGive.`,
  companies_and_bizGive_use_your_profile_to_perform_background_due_diligence_checks_on_your_organisation_it_is_only_visible_to_companies_you_apply_to: `Companies and BizGive use your profile to perform background due diligence checks on your organisation. It is only visible to companies you apply to.`,
  once_complete_you_will_be_able_to_apply_to_as_many_company_programmes_as_you_re_eligible_for_from_the_discover_tab: `Once complete, you will be able to apply to as many company programmes as you’re eligible for from the ‘Discover’ tab.`,
  profile_guidance: `Profile Guidance`,
  saves_your_data_and_moves_you_to_the_next_screen_your_answers_will_automatically_be_saved_unless_you_have_an_internet_connectivity_issue: `saves your data and moves you to the next screen. Your answers will automatically be saved, unless you have an internet connectivity issue.`,
  saves_your_data_and_logs_you_out_of_bizGive: `saves your data and logs you out of BizGive. You will be able to continue your profile when you log back in. You will need to fill in all required fields to ‘Save & Exit’ (you can put in random values e.g. X as placeholder, to click ‘Next’)`,
  entity_details: `Entity Details`,
  name_n_address: `Name & Address`,
  do_you_have_a_different_trading_name_or_display_name: `Do you have a different trading name or display name?`,
  legal_structure: `Legal Structure`,
  what_is_your_legal_structure: `What is your legal structure?`,
  registered_charity: `Registered Charity`,
  community_interest_company: `Community Interest Company`,
  charitable_company: `Charitable Company`,
  charitable_trust: `Charitable Trust`,
  academic_institution: `Academic Institution`,
  unregistered_organisation: `Unregistered organisation`,
  parish_or_community_council: `Parish or Community Council`,
  government_linked_entity: `Government Linked Entity`,
  for_profit_company: `For Profit Company`,
  what_is_your_registration_number: `What is your registration number?`,
  this_is_your_registration_number_with_the_relevant_regulator_of_your_entity: `This is your registration number with the relevant regulator of your entity.`,
  please_share_a_link_to_your_online_registration: `Please share a link to your online registration`,
  this_is_a_link_to_your_profile_on_the_regulators_website: `This is a link to your profile on the regulator’s website.`,
  please_upload_a_copy_of_your_governing_document: `Please upload a copy of your Governing documents`,
  these_are_not_available: `These are not available`,
  please_share_a_reason: `Please share a reason`,
  please_upload_a_copy_of_your_governing_documents: `Please upload a copy of your Governing documents`,
  for_example_your_trust_deed: `For example, your trust deed.`,
  please_upload_a_copy_of_your_most_recent_annual_accounts: `Please upload a copy of your most recent annual accounts`,
  for_example_your_Articles_of_association: `For example, your Articles of Association.`,
  please_upload_a_copy_of_your_constitution_trust_deed_or_rules: `Please upload a copy of your constitution, trust deed or rules`,
  please_upload_a_copy_of_your_registration_documents_or_most_recent_annual_accounts : `Please upload a copy of your registration documents or most recent annual accounts`,
  entity_types: `Entity Types`,
  registered_with_the_relevant_national_or_regional_regulatory_authority_for_example_the_charity_commission_in_the_uk: `Registered with the relevant national or regional regulatory authority. For example, the Charity Commission in the UK.`,
  community_interest_company_uk_only: `Community Interest Company (UK only)`,
  registered_with_companies_house: `Registered with Companies House. A special form of non-charitable limited company which exists to benefit a community or pursue a social purpose. Many social enterprises are CICs.`,
  a_type_of_charity_run_by_a_small_group_of_people_known_as_trustees: `A type of charity run by a small group of people known as trustees. In the UK, a trust has to register with the Charity Commission when it's annual income exceeds £5,000.`,
  an_educational_institution_such_as_a_university_or_school: `An educational institution such as a University or School.`,
  an_entity_that_is_not_registered_or_regulated_for_example_clubs_societies_and_small_community_groups: `An entity that is not registered or regulated. For example, clubs, societies and small community groups.`,
  a_type_of_charitable_organisation_registered_as_a_company_limited_by_guarantee: `A type of charitable organisation registered as a Company Limited by Guarantee or other nonprofit corporate structure. In the UK, they are registered with Companies House as a Company Limited by Guarantee.`,
  organisations_that_are_responsible_for_the_provision_of_public_services: `Organisations that are responsible for the provision of public services.`,
  trustees: `Trustees`,
  please_provide_the_name_of_your_trustees_or_directors: `Please provide the name of your trustees or directors.`,
  a_minimum_of_two_is_required_only_1_is_required_if_you_are_a_uk_cic: `A minimum of 2 is required. Only 1 is required if you are a UK CIC.`,
  where_are_you_able_to_run_your_projects: `Where are you able to run your projects?`,
  based_on_your_selections_we_will_be_able_to_recommend_relevant_new_funding_programmes_to_you: `Based on your selections, we will be able to recommend relevant new funding programmes to you.`,
  countries_of_operation: `Countries of Operation`,
  within_your_selected_countries_can_you_run_projects_nationwide_or_in_specific_locations_only: `Within your selected countries, can you run projects nationwide or in specific locations only?`,
  nationwide: `Nationwide`,
  specific_locations: `Specific Locations`,
  please_describe_what_your_organisation_does: `Please describe what your organisation does`,
  please_provide_a_brief_overview_of_your_groups_purpose_aims_and_objectives: `Please provide a brief overview of your group’s purpose, aims and objectives.`,
  social_media: `Social Media`,
  please_share_a_link_to_your_website: `Please share a link to your website`,
  if_applicable_please_share_your_social_media_links: `If applicable, please share your social media links`,
  please_add_your_entity_logo: `Please add your entity logo`,
  your_profile_is_complete: `Your Profile is Complete`,
  you_can: `You can`,
  edit_title_caption: `‘Edit’`,
  your_profile_at_any_point_by_clicking_into: `your profile at any point by clicking into ‘My Organisation’ > ’Profile’ > ‘Edit’`,
  what_happens_next: `What happens next?`,
  start_your_application: `Start Your Application`,
  if_you_accessed_bizGive_via_a_company_programme: `If you accessed BizGive via a company programme, click ‘Apply’ to start your application form.`,
  discover_company_programmes: `Discover Company Programmes`,
  click_discover_to_view_and_apply_to_live_company_support_programmes: `Click ‘Discover’ to view and apply to live company support programmes.`,
  enter_url: `Enter URL`,
  please_upload_documentation: `Please upload Documentation`,
  council_or_government_linked_entity: `Council or Government-Linked Entity`,
  locations: `Locations`,
  specific_locations_within_each_country: `Specific Locations within each country`,
  entity_type: `Entity Type`,
  legal_name: `Legal Name`,
  governing_documents: `Governing Documents`,
  operating_location: `Operating Location`,
  nationally_in_the_following_countries: `Nationally in the following countries:`,
  locally_in_the_following_countries: `Locally in the following countries:`,
  not_available: `Not Available`
};

export default enLocale;
