import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode'
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    EnvironmentFilled,
} from "@ant-design/icons";
import { Divider, Form, Spin, Avatar } from "antd";
import styled from "styled-components";
import { getProfileData, toggleSpinner, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getSecuredUrl } from "../../utils/utilities";
import LinkedinIcon from "../../assets/linkedin.png";
import InstaGramIcon from "../../assets/instagram.png";
import TwitterIcon from "../../assets/twitter.png";
import FacebookIcon from "../../assets/facebook.png";
import LinkComponent from "../../components/LinkComponent";
import {
    toggleSpinnerSelector,
    profileSelector,
    editCharityProfileSelector,
    tokenSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import fileIcon from "../../assets/file.svg";

const CharityProfilePreview = ({ className }) => {
    const baseClassName = clsx("charityProfilePreview", className);
    const intl = useIntl();
    const [logo, setLogo] = useState(null);
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenSelector) || localStorage.getItem("id_Token");
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID = '',
        socialMedia,
        tradingName,
        logoS3URL,
        documents,
        trustees,
        operatingLocation,
        registeredAddress,
        summary,
        entityType,
        legalName,
        nationallyActive } = {} } = useAppSelector(profileSelector) || {};
    const decodedToken = jwtDecode(token);
    const { completed } = JSON.parse(decodedToken["custom:referrer"]) || {}
    const { message } = useAppSelector(editCharityProfileSelector) || {};

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogo(securedURL);
    };

    const onDownloadFile = async (data) => {
        const { name, key = '' } = data || {};
        const getSecuredURL = await getSecuredUrl(key, name);
        const securedURL = URL.createObjectURL(getSecuredURL);
        const newTab = window.open(securedURL, '_blank');
        setTimeout(() => {
            URL.revokeObjectURL(securedURL);
            newTab?.close();
        }, 5000);

        return newTab;
    };

    useEffect(() => {
        if ((logoS3URL && typeof logoS3URL === 'string') || logoS3URL?.length > 0) {
            const url = logoS3URL?.[0]?.key || logoS3URL;
            setLogoImage(url)
        }
    }, [logoS3URL])

    useEffect(() => {
        if ((message) && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            if (!completed) {
                navigate("/impact-maker/profile/create/profile-end");
            } else {
                navigate("/impact-maker/profile");
            }
        }
    }, [message])

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    function getUniqueGroupedResultByDocumentType(data) {
        if (!Array.isArray(data) || !data.length) {
            return [];
        }

        const grouped = {};

        data.forEach(item => {
            const { documentType } = item;
            if (!grouped[documentType]) {
                grouped[documentType] = [];
            }
            const isDuplicate = grouped[documentType].some(
                existingItem => existingItem.uid === item.uid
            );

            if (!isDuplicate) {
                grouped[documentType].push(item);
            }
        });
        return Object.entries(grouped).map(([documentType, items]) => ({
            documentType,
            items,
        }));
    }


    const handleProceed = (e) => {
        const data = {
            ID,
            profilePercentage: "100"
        }
        dispatch(editCharityProfile(data))
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/profile/create/media")
    }

    const groupDocumentType = getUniqueGroupedResultByDocumentType(documents);

    console.log("groupDocumentType", groupDocumentType)

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='mainContentContainer'>
                <div className='contentContainer'>
                    <div className="titleContainer">
                        <div className='logoContentContainer'>
                            <div className='logoContainer'>
                                <img src={logo} alt="logo" className="hero-image" />
                            </div>
                        </div>
                    </div>
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "name" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {tradingName}
                    </StyledText>
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "description" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {summary}
                    </StyledText>
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "entity_details" })}
                    </StyledText>
                    <div className='subContainer'>
                        <div className='subContentContainerWrapper'>
                            <div>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "entity_type" })}
                                </StyledText>
                                <StyledText as="p" variant="B3">
                                    {entityType}
                                </StyledText>
                            </div>
                            <div>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "legal_name" })}
                                </StyledText>
                                <StyledText as="p" variant="B3">
                                    {legalName}
                                </StyledText>
                            </div>
                            <div>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "registered_address" })}
                                </StyledText>
                                <StyledText as="p" variant="B3">
                                    {registeredAddress?.addressLine1}
                                </StyledText>
                            </div>
                            <div>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "governing_documents" })}
                                </StyledText>
                                {Array.isArray(groupDocumentType) && groupDocumentType?.map((el) => <><div className='documentsContainer'><StyledText as="p" variant="B3" className="fileName">
                                    {el.documentType}
                                </StyledText>

                                    <div>
                                        {
                                            groupDocumentType
                                                .filter((group) => el.documentType === group.documentType)
                                                .map((filteredGroup) => {
                                                    return filteredGroup.items?.map((item) => {
                                                        if (item.na === "Y") {
                                                            return (
                                                                <StyledText as="p" variant="B3" key={item.uid}>
                                                                    {`${intl.formatMessage({ id: "not_available" })}: ${item.naReason}`}
                                                                </StyledText>
                                                            );
                                                        } else {
                                                            return (
                                                                <button
                                                                    className="subDocumentsContainer"
                                                                    key={item.uid}
                                                                    onClick={() => onDownloadFile(item)}
                                                                >
                                                                    <Avatar src={fileIcon} />
                                                                    <StyledText as="p" variant="B3" key={item.uid}>
                                                                        {item.name}
                                                                    </StyledText>
                                                                </button>
                                                            );
                                                        }
                                                    });
                                                })
                                        }
                                    </div>
                                </div>
                                    <Divider />
                                </>
                                )}
                            </div>
                        </div>
                        <div className='subContentContainerWrapper'>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "operating_location" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                {nationallyActive === 'Y' ? intl.formatMessage({ id: "nationally_in_the_following_countries" }) : intl.formatMessage({ id: "locally_in_the_following_countries" })}
                            </StyledText>
                            {operatingLocation?.map((item) => <div key={item} className="locationIcon">
                                <EnvironmentFilled style={{ color: 'red' }} />
                                <StyledText as="p" variant="B3" key={item} className="city">
                                    {item}
                                </StyledText>
                            </div>)
                            }
                        </div>
                        <div className='subContentContainerWrapper'>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "trustees" })}
                            </StyledText>
                            {trustees?.map((el) => <StyledText key={el?.name} as="p" variant="B3">
                                {el?.name}
                            </StyledText>)}
                        </div>
                    </div>
                    <div className='subContentContainer'>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "social_media" })}
                        </StyledText>
                        <div className='socialMediaContainer'>
                            <div className='linkContainer'>
                                <Avatar src={LinkedinIcon} />
                                <LinkComponent href={`http://${socialMedia?.linkedInURL}`}>
                                    <StyledText as="p" variant="B3b">
                                        {socialMedia?.linkedInURL}
                                    </StyledText>
                                </LinkComponent>
                            </div>
                            <div className='linkContainer'>
                                <Avatar src={FacebookIcon} />
                                <LinkComponent href={`http://${socialMedia?.facebookURL}`}>
                                    <StyledText as="p" variant="B3b">
                                        {socialMedia?.facebookURL}
                                    </StyledText>
                                </LinkComponent>
                            </div>
                            <div className='linkContainer'>
                                <Avatar src={InstaGramIcon} />
                                <LinkComponent href={`http://${socialMedia?.instagramURL}`}>
                                    <StyledText as="p" variant="B3b">
                                        {socialMedia?.instagramURL}
                                    </StyledText>
                                </LinkComponent>
                            </div>
                            <div className='linkContainer'>
                                <Avatar src={TwitterIcon} />
                                <LinkComponent href={`http://${socialMedia?.xURL}`}>
                                    <StyledText as="p" variant="B3b">
                                        {socialMedia?.xURL || socialMedia?.twitterURL}
                                    </StyledText>
                                </LinkComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buttonContainer'>
                    <Divider />
                    <div className='buttonContentContainer'>
                        <div className='nextAndPreviousContainer'>
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={() => handlePrevious()}>
                                {intl.formatMessage({ id: "previous" })}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleProceed()}
                                htmlType="button">
                                {intl.formatMessage({ id: "complete" })}
                            </Button>
                        </div>
                        <div className="saveAndExitButton">
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => handleSaveAndExit()}>
                                {intl.formatMessage({ id: "save_n_exit" })}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledCharityProfilePreview = styled(CharityProfilePreview)`
&.charityProfilePreview{
    background: ${(props) => props.theme.backgroundCanvas};
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    height: 180vh;
    .googlePlaceInputItem{
        width: 16vw;
    }
    .subDocumentsContainer{
        display: flex;
        gap: 5px; 
        align-items: center;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
    }
    .fileName{
        width: 300px;
    }
    .subContentContainerWrapper{
        padding: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: -10px;
    border-radius: 10px;
    }
    .documentsContainer{
        display: flex;
        align-items: center;
        padding-block: 5px;
    }
    .socialMediaContainer{
        display: flex;
        width: 30vw;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
        .subSocialMediaContainer{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    .subContainer{
        padding-block: 10px;
        display: flex;
            flex-direction: column;
            gap: 40px;
    }
    .locationIcon {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .formItem .ant-form-item-explain-error{
        margin-top: -20px !important;
    }
    .wordCount{
        text-align: right;
        &.error{
            color: red;
        }
    }
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .titleContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .editButton{
                padding-inline: 50px;
            }
            .logoContentContainer{
            display: flex;
            align-items: center;
            .logoContainer{
                height: 100px;
                width: 100px;
                border-radius: 50%;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
               
            }
        }
        }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .subContentContainer{
            display: flex;
        flex-direction: column;
        gap: 10px;
        .linkContainer{
        display: flex;
        align-items: center;
        gap: 10px;
       }
        }
}`;

export default StyledCharityProfilePreview;
